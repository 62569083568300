import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Column,
  H4, H5,
  IconPolish,
  ModalDialog,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogFooter,
  ModalDialogButtons,
  ModalDialogNav,
  Row,
  TextInput,
  TextInputWithFloatingLabel,
  TextInputFloatingLabel,
  Typography,
} from '@vp/swan';
import {
  SECTION_HDR_COLOR,
  SECTION_HDR_PAD,
  SECTION_HDR_MARGIN,
  SECTION_HDR_BORDER
} from '../components/shared/AppStyle';
import CSVExporter from '../util/CSVExporter';
import { useFileData } from "../state/FileDataContexts";
import { useActivityLoggerDispatch } from './activityLogs/ActivityLogger';
import { getOrders } from '../apis/omsApi.js';
import {
  REFRESH_ORDER_DATA_FAILURE,
  REFRESH_ORDER_DATA_SUCCESS
} from './activityLogs/activityLoggerDispatchActions';
import {
  createDownloadFileName,
  convertFileDataToArray,
  getFileDataHeaders,
} from '../util/orderUtils';

/**
 * Allow user to download results of remediation so they can be included in a ticket report.
 * Data will come from Dali, not from the tool.
 * @param {*} param0 
 * @returns 
 */
const DownloadResults = ({ title, bottomMargin = 1 }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filename, setFilename] = useState(createDownloadFileName());
  const [dialogMessage, setDialogMessage] = useState('Waiting to refresh data...');
  const [canShowExporter, setCanShowExporter] = useState(false);
  const [refreshedData, setRefreshedData] = useState([]);
  const fileData = useFileData();
  const activityLoggerDispatch = useActivityLoggerDispatch();
  const env = fileData.meta.environment;

  /**
   * Fetch the orders listed in 'fileData' from OMS.
   */
  const fetchOrders = async () => {
    //activityLoggerDispatch(LOAD_DATA_TABLE_START());
    try {
      const omsEntries = await getOrders(env, fileData, true, false);
      if (omsEntries && omsEntries.length > 0) {
        setDialogMessage(`Fetched ${omsEntries.length} entries from OMS to download.`);
        const csvData = convertFileDataToArray(omsEntries);
        setCanShowExporter(true);
        setRefreshedData(csvData);
      }
      activityLoggerDispatch(REFRESH_ORDER_DATA_SUCCESS());
    } catch (err) {
      activityLoggerDispatch(REFRESH_ORDER_DATA_FAILURE());
      //errorNotifierDispatch(createApiError("GetOrder", `Request failed with status ${err.errorStatusCode}`));
    }
  };

  /**
   * 
   * @param {boolean} toShow If true, show modal; if false, hide it. 
   */
  const showModal = (toShow) => {
    if (toShow) {
      if (fileData.length && fileData.length > 0) {
        setIsModalOpen(true);
      }
      fetchOrders();
    } else {
      setIsModalOpen(false);
    }
  }

  return (
    <>
      <Row
        margin={SECTION_HDR_MARGIN}
        backgroundColor={SECTION_HDR_COLOR}
        padding={SECTION_HDR_PAD}
        style={SECTION_HDR_BORDER}
      >
        <H4><IconPolish />{" "}{title}</H4>
      </Row>
      <Row>
        <Column offset={1}>
          <Button
            size="mini"
            onClick={() => showModal(true)}
            margin={3}
          >
              Download results
            </Button>
            <ModalDialog isOpen={isModalOpen} onRequestDismiss={() => showModal(false)}>
            <ModalDialogContent style={{ maxWidth: '60%', minWidth: '30%' }}>
              <ModalDialogNav>
                <ModalDialogCloseButton
                  visuallyHiddenLabel="Close"
                  onClick={() => showModal(false)} />
              </ModalDialogNav>
              <ModalDialogBody>
                <Row>
                  <H4>Download Remediation Results</H4><br />
                </Row>
                <Row>
                  <Typography>{dialogMessage}</Typography>
                </Row>
                <Row margin={8}>
                  <TextInputWithFloatingLabel mb={5}>
                    <TextInput
                      size="mini"
                      placeholder={filename}
                      value={filename}
                      onChange={event => {
                        setFilename(event.target.value);
                      }}
                    >
                  </TextInput>
                  <TextInputFloatingLabel>Edit Download Filename</TextInputFloatingLabel>
                  </TextInputWithFloatingLabel>
                  </Row>
                {canShowExporter && (
                  <CSVExporter
                    title={`Download to "${filename}"`}
                    filename={filename} 
                    csvData={refreshedData}
                    headers={getFileDataHeaders(fileData)}
                  />
                )}
                </ModalDialogBody>
                <ModalDialogFooter>
                  {/* <ModalDialogButtons>
                    <Button onClick={() => showModal(false)}>Close</Button>
                  </ModalDialogButtons> */}
                </ModalDialogFooter>
              </ModalDialogContent>
            </ModalDialog>
        </Column>
      </Row >
      <Row mb={bottomMargin} />
    </>
  )
}

DownloadResults.propTypes = {
  title: PropTypes.string,
  bottomMargin: PropTypes.number,
};
export default DownloadResults;