import ErrorSeverity from '../components/errors/ErrorSeverity';
import errorTypes from '../components/errors/errorTypes';
/** Standard API timeout */
export const TIMEOUT_MS = 10000;

export function createStandardHeaders(token) {
  const auth = `Bearer ${token}`;
  return {
    Authorization: auth,
    Accept: 'application/json',
  };
}

export function createPostHeaders(token) {
  const h = createStandardHeaders(token);
  h['Content-Type'] = 'application/json';
  return h;
}

/**
 * Creates an OMS API Error object for errors detected BEFORE making the API call.
 * @param {*} caller Calling method.
 * @param {*} reason Displayed reason.
 * @returns 
 */
export const createApiError = (caller, reason) => {
  const msg = caller + ": " + reason;
  const err = new Error(msg);
  err.severity = ErrorSeverity.ERROR;
  err.type = errorTypes.OMS_API;
  return err;
}

/**
 * Creates an Warning object for warnings that should be shown to the user due to a user action.
 * @param {*} caller 
 * @param {*} message 
 * @returns 
 */
export const createUiWarning = (caller, message) => {
  const msg = caller + ": " + message;
  const err = new Error(msg);
  err.severity = ErrorSeverity.WARNING;
  err.type = errorTypes.USER_UI_ERROR;
  return err;
}

export function transformAttributesListToObj(attrStrArray) {
  const rtnObj = {} ;

  if (!attrStrArray) {
    return rtnObj;
  }

  attrStrArray.forEach((line) => {
    const nmValArr = line.split(':');
    const nm = nmValArr[0];
    const val = nmValArr[1];
    rtnObj[nm] = val;
  });
  return rtnObj;
}

/**
 * Given an object whose property values represent product attributes, 
 * return those product attributes an array of ':' separated strings.
 * 
 * @param {*} attrObj 
 * @returns 
 */
export function transformAttributesObjToList(attrObj) {
  const rtnList = [];

  if (!attrObj) {
    return rtnList;
  }
  
  Object.keys(attrObj).forEach(key => {
    rtnList.push(key + ":" + attrObj[key]);
  })
  return rtnList;
}