import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
// import { join } from 'path';
import {
  GridContainer,
  Row,
  Column,
  Typography,
  BoundedContent,
  FlexBox,
  Box,
} from '@vp/swan';
import DevHomeSignInPanel from '../components/shared/DevHomeSignInPanel';
import { IdentityProvider } from '../components/contexts/IdentityContext';
import { SwanEnabler } from '../components/shared/SwanEnabler';
import AppSession from '../components/AppSession';

const Index = () => (
  <IdentityProvider>
    {/* On page load, you might notice a flash of unstyled content (FOUC). Don't panic, this is a side effect of
          local static site generation that does not occur in prod environments!
          Read more: https://www.gatsbyjs.com/docs/conceptual/overview-of-the-gatsby-build-process/ */}
    <Helmet>
      <title>BORED (Bulk Order REmeDiator)</title>
    </Helmet>
    <SwanEnabler>
      <main className="site-main">
        <Box backgroundColor="light-grey" p={6} mb={9}>
          <BoundedContent>
            <GridContainer>
              <Row>
                <Column span={12}>
                  <FlexBox justifyContent="center" mb={6}>
                    <Typography
                      component="h1"
                      mb={4}
                      mt={4}
                      textColor="dark-grey"
                    >
                      BORED (Bulk Order REmeDiator)
                    </Typography>
                  </FlexBox>
                </Column>
              </Row>
              <Row>
                <Column span={12}>
                  <DevHomeSignInPanel />
                </Column>
              </Row>
              {/* <Row>
                    <Column span={12}>
                      <SiteFilters
                        filters={filters}
                        allCountries={allCountries}
                      />
                    </Column>
                  </Row> */}
              <Row>
                <Column span={12}>
                  {/* <Row>
                        <Column span={3} offset={3}>
                          <FlexBox>
                            <Typography fontWight="bold">
                              Client Site
                            </Typography>
                          </FlexBox>
                        </Column>
                        <Column span={3}>
                          <FlexBox>
                            <Typography fontWeight="bold">
                              Language/Locale
                            </Typography>
                          </FlexBox>
                        </Column>
                      </Row>
                      <br /> */}
                  <Row>
                    <AppSession />
                  </Row>
                  {/* {visibleNodes.map(
                        ({
                          siteName,
                          uiLocalization: { language, locale },
                        }) => {
                          const navigateToSite = navigate(pathMap[siteName]);
                          return (
                            <React.Fragment key={siteName}>
                              <Row key={siteName}>
                                <Column span={3} offset={3}>
                                  <FlexBox justifyContent="left">
                                    <Link
                                      render={p => (
                                        <Button
                                          skin="link"
                                          className={p.className}
                                          onClick={navigateToSite}
                                        >
                                          {p.children}
                                        </Button>
                                      )}
                                    >
                                      {siteName}
                                    </Link>
                                  </FlexBox>
                                </Column>
                                <Column span={3}>
                                  <FlexBox justifyContent="left">
                                    <Typography>
                                      {language}, {locale}
                                    </Typography>
                                  </FlexBox>
                                </Column>
                              </Row>
                            </React.Fragment>
                          );
                        }
                      )} */}
                </Column>
              </Row>
            </GridContainer>
          </BoundedContent>
        </Box>
      </main>
    </SwanEnabler>
  </IdentityProvider>
);

export default Index;
