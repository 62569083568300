import { trackError } from '@vp/om-newrelic-tracker';
import { observableAxiosClient } from './axios/observableAxiosClient';
import getIdTokenAndShopperId from './auth';
import { createPostHeaders, TIMEOUT_MS } from './apiUtils';

const SELECTOR_API_BASE_URL = 'https://selector-api.products.cimpress.io';
// NOTE: No staging version needed.

/**
 * Fetch product attributes from Selector API
 * @param {string} mcpSku Required fulfillment SKU.
 * @param {integer} mcpSkuVersion Required fulfillment SKU version.
 * @param {object} selections Required object with selected option key/value pairs.
 * * @param {string} country Required country for context.
 * @returns
 */
export async function fetchProductAttributes(mcpSku, mcpSkuVersion, selections, country) {
  const { shopperId, idToken } = getIdTokenAndShopperId();
  
  if (!selections) {
    selections = {};
  }

  if (!idToken || !mcpSku || !mcpSkuVersion) {
    //TODO error
    return;
  }
  const postData = buildSelectorAPIFetchData(selections, country);
  try {
    const response = await observableAxiosClient({
      url: `${SELECTOR_API_BASE_URL}/v1/products/${mcpSku}/versions/${mcpSkuVersion}:validate`,
      method: 'post',
      data: postData,
      headers: createPostHeaders(idToken),
      timeout: TIMEOUT_MS,
    });
    return processSelectorAPIResults(response.data);
  } catch (err) {
    trackError(err, { errorName: 'product-attributes-fetch' });
    throw err;
  }
}

/**
 * Construct data for Selector API post call.
 * @param {*} selections 
 * @param {*} country 
 * @returns 
 */
const buildSelectorAPIFetchData = (selections,country) => {
  const postData = {
    selections,
    includeBuckets: ["option"],
    context: { Country: country},
  };
  return JSON.stringify(postData);
};

/**
 * Convert Selection API results to attribute array.
 * @param {*} selections 
 * @param {*} country 
 * @returns 
 */
const processSelectorAPIResults = (data) => {

  // Copied logic from Bored Retool app
  const remainingOpts = data.remainingOptions;
  const attributes = [];

  // For each attribute...
  remainingOpts.forEach(opt => {
    const key = opt.key;
    const attrType = opt.type;
    if (key === 'Quantity') {
      // Skip this for now.
      return;
    }

    let valArr = [];
    if (attrType === 'string') {
      valArr = opt.values.map(v => v.stringLiteral);
    }
    else if (attrType === 'numeric') {
      // debugger;
      let numericStr = '';
      for (var i = 0; i < opt.values.length; i++) {
        const curValue = opt.values[i];
        if (curValue.type === "numberLiteral") {
          numericStr = curValue.numberLiteral;
          const numVal = Number.parseInt(numericStr);
          valArr.push(numVal);
        } 
        else if (curValue.type === "range") {
          const min = curValue.range.minimum || 10;
          const max = curValue.range.maximum || 1;
          const incr = curValue.range.increment || 1;
          valArr.push(min);
          for (var j = min; j < max; j += incr) {
            valArr.push(min + (j * incr));
          }
        } 
        else {
          console.log(" ERROR: Ignoring unknown numeric type " + curValue.type);
        }
      }
    } 
    else {
      console.log("S19: ERROR: Ignoring unknown attr type " + attrType);
    }

    // TBD:
    // Now find selected value from currentOrderAttribute State.
    // let selectedAttrValue = ...
    
    // Fill in the whole table.
    attributes.push({
      attribute: key,
      attrValues: valArr,
      //selectedValue: selectedAttrValue,
      displayName: key,
      displayValue: '',
      isChanged: false
    });
  });

  //console.log("S19: Updated product attributes: " + JSON.stringify(attributes));

  return attributes;

}