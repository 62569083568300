import React from 'react';
import { useErrorNotifierState } from './ErrorNotifier';
import { ErrorNotification } from './ErrorNotification';

/**
 * Error notification for useQuery/useMutation failures
 */
export const ErrorNotifications = () => {
  const { errorNotifierState } = useErrorNotifierState();

  if (errorNotifierState?.errors?.length > 0) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    errorNotifierState?.errors &&
    errorNotifierState.errors
      .sort((a, b) => (a.type > b.type ? 1 : -1)) // keep the error notification display order
      .map(
        err => !!err.message && <ErrorNotification error={err} key={err.type} />
      )
  );
};
