import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Count,
  Typography,
  Collapsible,
  CollapsibleSummary,
  CollapsibleContent,
  CollapsibleSummaryButton,
  CollapsibleSummaryTextOpen,
  CollapsibleSummaryTextClosed,
} from "@vp/swan";

const ATTR_TEXT_SIZE = 7;
const CROSS_OUT_TEXT_STYLE = { 'text-decoration': 'line-through' };
const NEW_ATTR_COLOR = "holiday";

/**
 * Display of a list of attributes.  Handles both existing attributes and new/deleted attributes.
 * @param {*} param0 
 * @returns 
 */
const AttributesDisplay = ({ attributes, openLabel, areNewAttrs, newAttributes, deletedAttributes }) => {
  const attrNames = attributes ? Object.getOwnPropertyNames(attributes) : [];
  const deletedList = deletedAttributes ? deletedAttributes : [];
  const newAttributesObj = newAttributes ? newAttributes : {};
  const attrCount = attrNames.length + deletedList?.length;
  const getColor = (attributeName) => {
    //this flag overrides
    if (areNewAttrs) {
      return NEW_ATTR_COLOR;
    }
    //check if the attribute was changed
    if ( newAttributesObj[attributeName] ) {
      return NEW_ATTR_COLOR;
    } else {
      return "black";
    }
  };
  
  if (attrCount > 0) {
    return (
      <>
        <Collapsible>
          <CollapsibleSummary >
            <CollapsibleSummaryButton mt={0} mb={0} pb={1} pt={1}>
              <CollapsibleSummaryTextOpen>
                {openLabel}
              </CollapsibleSummaryTextOpen>
              <CollapsibleSummaryTextClosed>
                <Count>{attrCount}  attributes</Count>
              </CollapsibleSummaryTextClosed>
            </CollapsibleSummaryButton>
          </CollapsibleSummary>
          <CollapsibleContent>
            {attributes && Object.getOwnPropertyNames(attributes).map((o, i) => (
              <Typography
                textSize={ATTR_TEXT_SIZE}
                textColor={getColor(o)}
              >{o + ":" + attributes[o]}</Typography>
            ))}
            {deletedList.map((a, i) => (
              <Typography
                textSize={ATTR_TEXT_SIZE}
                style={CROSS_OUT_TEXT_STYLE}
                textColor={NEW_ATTR_COLOR}
              >{a}</Typography>
            ))}
          </CollapsibleContent>
        </Collapsible>
      </>
    );
  }
  return <></>;
};

AttributesDisplay.propTypes = {
  attributes: PropTypes.object,
  openLabel: PropTypes.string,
  areNewAttrs: PropTypes.bool,
  deletedAttributes: PropTypes.array,
};

export default AttributesDisplay;
