import {
  COL_ITEM_ID,
  COL_ORDER_NUMBER,
  COL_PRODUCT_KEY,
  COL_PRODUCT_VERSION,
  COL_SKU,
  COL_SKU_VERSION,
  COL_VARIABLE_ATTR,
  PROP_NEW_VARIABLE_ATTRS,
  PROP_DELETED_VARIABLE_ATTRS,
  COL_DELETED_VARIABLE_ATTR,
} from './orderUtils.js';

/**
 * Validator
 * Validates CSV file data.
 * Throws error if there is any missing or misconfigured data.
 * Sets flags on input data under certain conditions.
 */

const getColumnIndexByName = (headerArray, columnName) => {
  let colIndex = -1;
  for (var i = 0; i < headerArray.length; i++) {
    if (headerArray[i] === columnName) {
      colIndex = i;
      break;
    }
  }
  return colIndex;
}

/**
 * Check the headers in an input file.
 * @param {*} headerArray 
 * @param {*} dataArr 
 * @throws Error on any missing field.
 */
const checkHeaderRow = (headerArray, dataArr) => {
  console.log("Header: " + JSON.stringify(headerArray) + "\nData: " + JSON.stringify(dataArr));
  let hasItemId = false;
  let hasOrderNumber = false;
  if (headerArray.length < 1) {
    throw new Error('Invalid column header row - at least orderNumber is required.');
  }
  for (var i = 0; i < headerArray.length; i++) {
    const hName = headerArray[i];
    if (COL_ORDER_NUMBER === hName) {
      hasOrderNumber = true;
    }
    if (COL_ITEM_ID === hName) {
      hasItemId = true;
    }
  }
  // if (!hasItemId) {
  //   throw new Error('Missing required "itemId" column header');
  // }
  if (!hasOrderNumber) {
    throw new Error('Missing required "orderNumber" column header');
  }
}

/**
 * Verify that if a columnName is present, all rows have a value. 
 * @param {array} headerArray 
 * @param {array} dataArr 
 * @param {string} columnName 
 * @param {boolean} If true, check that each value is a number.
 * @returns true if all rows have a value in the indicated column.
 */
const checkColumn = (headerArray, dataArr, columnName, isNumber) => {
  let skuIndex = getColumnIndexByName(headerArray, columnName);
  if (skuIndex < 0) {
    // nothing to do
    return false;
  }
  // Make sure each row has a value.
  for (var rowIndex = 0; rowIndex < dataArr.length; rowIndex++) {
    const row = dataArr[rowIndex];
    const val = row[skuIndex];
    if (!val) {
      throw new Error(`Row ${rowIndex} has no value for "${columnName}"`);
    }
    if (isNumber) {
      const valAsNumber = parseInt(val);
      if (isNaN(valAsNumber)) {
        throw new Error(`${columnName} in data row ${rowIndex} must be a number`);
      }
    }
  }
  return true;
}

const checkSkuColumn = (headerArray, dataArr) => {
  const hasNewSku = checkColumn(headerArray, dataArr, COL_SKU, false);
  if (hasNewSku) {
    dataArr.hasNewSku = true;
  }
}

const checkSkuVersionColumn = (headerArray, dataArr) => {
  const hasNewSkuVersion = checkColumn(headerArray, dataArr, COL_SKU_VERSION, true);
  if (hasNewSkuVersion) {
    dataArr.hasNewSkuVersion = true;
  }
}

const checkProductVersionColumn = (headerArray, dataArr) => {
  const hasNewProductVersion = checkColumn(headerArray, dataArr, COL_PRODUCT_VERSION, true);
  if (hasNewProductVersion) {
    dataArr.hasNewProductVersion = true;
  }
}

const checkProductKeyColumn = (headerArray, dataArr) => {
  const hasNewProductKey = checkColumn(headerArray, dataArr, COL_PRODUCT_KEY, false);
  if (hasNewProductKey) {
    dataArr.hasNewProductKey = true;
  }
}

/**
 * Check that all values of a given column name are the same.
 * @param {*} headerArray 
 * @param {*} dataArr 
 * @param {*} columnName 
 * @returns true if there are no values of 'columnName' or all 'columnName' values are the same.
 */
const checkSameValue = (headerArray, dataArr, columnName) => {
  const vals = new Set();
  let colIndex = getColumnIndexByName(headerArray, columnName);
  if (colIndex < 0) {
    return true;
  }
  for (var rowIndex = 0; rowIndex < dataArr.length; rowIndex++) {
    const rowObj = dataArr[rowIndex];
    const val = rowObj[colIndex];
    if (val) {
      vals.add(val);
    }
  }
  if (vals.size > 1) {
    return false;
  }
  return true;
}

/** Check that all 'newSku' values are the same. */
const checkAllSkusAreSameValue = (headerArray, dataArr) => {
  const allSkusSame = checkSameValue(headerArray, dataArr, COL_SKU);
  if (!allSkusSame) {
    dataArr.hasDifferentSkus = true;
  }
}

/** Check that all 'newSkuVersion' values are the same. */
const checkAllSkuVersionsAreSameValue = (headerArray, dataArr) => {
  const allSkuVersionsSame = checkSameValue(headerArray, dataArr, COL_SKU_VERSION);
  if (!allSkuVersionsSame) {
    dataArr.hasDifferentSkuVersions = true;
  }
}

/**
 * Add to (or create) object containing new key-value attribute.
 * @param {*} newRowObj 
 * @param {*} dataRow 
 * @param {*} colIndex 
 * @returns 
 */
const handleNewVariableAttribute = (newRowObj, dataRow, colIndex) => {
  if ((colIndex - 1) >= dataRow.length) {
    // Error - no data for this attribute column!
    return;
  }
  if (!newRowObj[PROP_NEW_VARIABLE_ATTRS]) {
    newRowObj[PROP_NEW_VARIABLE_ATTRS] = {};
  }
  const attrsObj = newRowObj[PROP_NEW_VARIABLE_ATTRS];
  const keyValStr = dataRow[colIndex];
  const keyValArr = keyValStr.split(':');
  attrsObj[keyValArr[0]] = keyValArr[1];
}

/**
 * Add to (or create) list of deleted attributes from file.
 * @param {*} newRowObj 
 * @param {*} dataRow 
 * @param {*} colIndex 
 * @returns 
 */
const handleDeletedVariableAttribute = (newRowObj, dataRow, colIndex) => {
  if ((colIndex - 1) >= dataRow.length) {
    // Error - no data for this attribute column!
    return;
  }
  if (!newRowObj[PROP_DELETED_VARIABLE_ATTRS]) {
    newRowObj[PROP_DELETED_VARIABLE_ATTRS] = [];
  }
  const attrsList = newRowObj[PROP_DELETED_VARIABLE_ATTRS];
  const keyStr = dataRow[colIndex];
  attrsList.push(keyStr);
}

/**
 * Create the data model based on the CSV data.
 * This should be the last step for a validated file.
 * @param {string} Environment to use.
 * @param {*} headerArray Header array from file.
 * @param {*} dataArr Array of rows from file.
 * @returns An array of objects with properties that will be displayed.
 */
const createDataModel = (env, headerArray, dataArr) => {
  const dataModel = [];
  let hasNewVariableAttributes = false;
  let hasDeletedVariableAttributes = false;
  // Iterate down the rows of data.
  for (var rowIndex = 0; rowIndex < dataArr.length; rowIndex++) {
    const dataRow = dataArr[rowIndex];
    const newRowObj = {};
    // Iterate across the columns and copy the values.
    for (var i = 0; i < headerArray.length; i++) {
      const propName = headerArray[i];
      // Handle new/deleted variable attrs separately - can have multiple columns, each defining an attr.
      if (propName === COL_VARIABLE_ATTR) {
        handleNewVariableAttribute(newRowObj, dataRow, i);
        hasNewVariableAttributes = true;
      } else if (propName === COL_DELETED_VARIABLE_ATTR) {
        handleDeletedVariableAttribute(newRowObj, dataRow, i);
        hasDeletedVariableAttributes = true;
      } else {
        newRowObj[propName] = dataRow[i];
      }
    }
    dataModel.push(newRowObj);
  }
  // Now copy attributes about the data array as a whole onto the 'meta' attribute.
  const meta = {};
  let anyFileChanges = false;
  if (headerArray.length === 1) {
    meta.isOrderOnlyMode = true;
  } else {
    meta.isOrderOnlyMode = false;
  }
  if (dataArr.hasNewSku) {
    meta.hasNewSku = true;
    anyFileChanges = true;
  }
  if (dataArr.hasNewSkuVersion) {
    meta.hasNewSkuVersion = true;
    anyFileChanges = true;
  }
  if (dataArr.hasNewProductKey) {
    meta.hasNewProductKey = true;
    anyFileChanges = true;
  }
  if (dataArr.hasNewProductVersion) {
    meta.hasNewProductVersion = true;
    anyFileChanges = true;
  }
  if (hasNewVariableAttributes) {
    meta.hasNewVariableAttributes = true;
    anyFileChanges = true;
  }
  if (hasDeletedVariableAttributes) {
    meta.hasDeletedVariableAttributes = true;
    anyFileChanges = true;
  }
  if (dataArr.hasDifferentSkus) {
    meta.hasDifferentSkus = true;
  }
  if (dataArr.hasDifferentSkuVersions) {
    meta.hasDifferentSkuVersions = true;
  }

  // If any file changes, we should disable UI facility for changing values.
  meta.anyFileChanges = anyFileChanges;
  meta.environment = env;
  dataModel.meta = meta;
  return dataModel;
}

const VALIDATION_STEPS = [
  checkHeaderRow,
  checkSkuColumn,
  checkSkuVersionColumn,
  checkProductKeyColumn,
  checkProductVersionColumn,
  checkAllSkusAreSameValue,
  checkAllSkuVersionsAreSameValue,
];

const Validator = {};
/**
 * Input Data Validation Engine.
 * @param {string} env Environment to use.
 * @param {*} data as it is read by the CSV reader, with headers in row[0].
 * @returns Data model object.
 */
Validator.validate = (env, data) => {
  if (!data || data.length === 0) {
    throw new Error("No data in file!");
  }
  if (data.length === 1) {
    throw new Error("No data, only header row in file!");
  }
  const headers = data[0];
  const dataArr = data.slice(1);
  for (var i = 0; i < VALIDATION_STEPS.length; i++) {
    const stepFn = VALIDATION_STEPS[i];
    stepFn(headers, dataArr);
  }
  // Must be last step
  const dataModel = createDataModel(env, headers, dataArr);
  return dataModel;
}

export default Validator;

