import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import VistaprintAuth from '@vp/auth';

const IdentityContext = React.createContext();

const IdentityProvider = props => {
  const { children } = props;
  const [auth, setAuthState] = useState();
  const [isIdentityInitialized, setIdentityInitialized] = useState(false);
  const [identity, setIdentity] = useState();
  const locale = 'en-us';
  const AUTH_CONFIG = {
    culture: locale,
    options: {
      connection: 'vistaprintcom-waad',
    },
    developmentMode: { clientID: 'WlovdIQc4auVgTkaFmxtgM9mVo5MvJsI' },
  };
  

  useEffect(() => {
      VistaprintAuth.init(AUTH_CONFIG, () => {
      const authLibrary = new VistaprintAuth.WebAuth();
      setAuthState(authLibrary);
    });
  }, [locale]);

  /**
   * Only attach this event handler once the auth library becomes available.
   * Since auth library is set in a run-once effect (above) this will only run a maximum once.
   */
  useEffect(() => {
    if (!auth) return;

    setIdentity(auth.getIdentity());

    auth.onUserIdentityUpdate(newIdentity => {
      if (!newIdentity) {
        return;
      }
      setIdentityInitialized(true);
      setIdentity(newIdentity);
    });
  }, [auth]);

  const identityContextValues = useMemo(
    () => ({
      isIdentityInitialized,
      identity,
      auth,
    }),
    [isIdentityInitialized, identity, auth]
  );

  return (
    <IdentityContext.Provider value={identityContextValues}>
      {children}
    </IdentityContext.Provider>
  );
};

IdentityProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { IdentityContext, IdentityProvider };
