import errorTypes from './errorTypes';
import ErrorSeverity from './ErrorSeverity';

/**
 * Non-API errors.
 * @param {*} fileName 
 * @param {*} msg 
 * @returns 
 */
export const BAD_FILE_ERROR = (fileName, msg) => {
  return {
    type: errorTypes.BAD_FILE,
    severity: ErrorSeverity.ERROR,
    message: `Unable to parse data in file "${fileName}": ${msg}.`
  };
};
export const INVALID_MANUAL_INPUT = (msg) => {
  return {
    type: errorTypes.INVALID_INPUT,
    severity: ErrorSeverity.ERROR,
    message: msg
  }
}
export const CLEAR_ERRORS = () => {
  return {
    type: errorTypes.CLEAR_ERRORS,
    message: ""
  };
};

