import Axios from 'axios';

const pathSplitRegex = /[/?#]/;
const timeoutErrorMessageRegex = /timeout/;

const UNKNOWN_ERROR_PROPERTY = 'UNKNOWN';

const parseDomainFromURL = url => {
  if (!url || typeof url !== 'string') return '';
  const urlFragments = url
    .replace('http://', '')
    .replace('https://', '')
    .split(pathSplitRegex);
  const domain =
    urlFragments && urlFragments.length > 0
      ? urlFragments[0]
      : UNKNOWN_ERROR_PROPERTY;
  return domain;
};

export const observableAxiosClient = Axios.create({});

const noOpResponseInterceptor = response => response;

export const errorInterceptor = error => {
  // throw original error if axios is not the root cause
  if (!error.isAxiosError) {
    throw error;
  }

  // The request was made and the server responded with a status code
  // that falls out of the range of 2xx
  if (error.response) {
    const errorStatusCode = error.response?.status || UNKNOWN_ERROR_PROPERTY;
    const errorHttpMethod =
      error.response?.config?.method || UNKNOWN_ERROR_PROPERTY;
    const errorURL = error.response?.config?.url || null;
    const errorDomain = parseDomainFromURL(errorURL);

    const customError = new Error();
    customError.message = `[AXIOS][STATUSCODE=${errorStatusCode}][METHOD=${errorHttpMethod}][DOMAIN=${errorDomain}]`;
    customError.errorStatusCode = errorStatusCode;
    customError.errorDomain = errorDomain;
    customError.errorHttpMethod = errorHttpMethod;
    customError.rootStack = error.stack;
    customError.axiosResponse = error.response;
    customError.axiosRequest = error.request;
    customError.axiosConfig = error.config;
    customError.isRetryable = errorStatusCode > 499 && errorStatusCode < 600; // Mark error as retryable when it's a 5xx error
    throw customError;
  } else if (error.request) {
    const errorURL = error.config?.url || null;
    const errorDomain = parseDomainFromURL(errorURL);
    const errorHttpMethod = error.config?.method || UNKNOWN_ERROR_PROPERTY;

    if (timeoutErrorMessageRegex.test(error.message)) {
      const customError = new Error(
        `[AXIOS][TIMEOUT][METHOD=${errorHttpMethod}][DOMAIN=${errorDomain}]`
      );
      customError.rootStack = error.stack;
      customError.axiosRequest = error.request;
      customError.axiosConfig = error.config;
      customError.errorStatusCode = -1;
      customError.isRetryable = true; // Mark error as retryable when we timed out
      throw customError;
    } else {
      const customError = new Error(
        `[AXIOS][NO-RESPONSE][METHOD=${errorHttpMethod}][DOMAIN=${errorDomain}]`
      );
      customError.rootStack = error.stack;
      customError.axiosRequest = error.request;
      customError.axiosConfig = error.config;
      customError.errorStatusCode = -1;
      throw customError;
    }
  } else {
    // Error occured during request setup/preflight
    throw error;
  }
};

observableAxiosClient.interceptors.response.use(
  noOpResponseInterceptor,
  errorInterceptor
);
