import React, { useState, useEffect, useReducer } from 'react';
import {
  Button,
  ModalDialog,
  ModalDialogBody,
  ModalDialogButtons,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogTitle,
  TextInput,
  TextInputFloatingLabel,
  TextInputWithFloatingLabel,
} from '@vp/swan';
import { COL_ORDER_NUMBER, COL_ITEM_ID } from './orderUtils';
import { useErrorNotifierDispatch } from '../components/errors/ErrorNotifier';
import { INVALID_MANUAL_INPUT } from '../components/errors/errorNotifierDispatchActions';

const SingleOrderInput = ({ onDataUploadFn }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const [itemId, setItemId] = useState('');
  const { errorNotifierDispatch } = useErrorNotifierDispatch();

  /**
   * Generate a single row of CSV data (plus header) with order number and item ID.
   */
  const generateData = () => {
    if (!orderNumber || !itemId) {
      errorNotifierDispatch(INVALID_MANUAL_INPUT('You need to specify both order number and item ID for single order.'));
      setIsOpen(false);
      return;
    }
    const csvData = [
      [COL_ORDER_NUMBER, COL_ITEM_ID],
      [orderNumber, itemId],
    ];
    const fInfo = { name: "Manual Input" };
    onDataUploadFn(csvData, fInfo);
    setOrderNumber('');
    setItemId('');
    setIsOpen(false);
  }

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
      >
        Input single order
      </Button>
      <ModalDialog
        isOpen={isOpen}
        onRequestDismiss={() => setIsOpen(false)}
        data-testid="single-order-modal"
      >
        <ModalDialogContent aria-labelledby="modal dialog general">
          <ModalDialogCloseButton accessibleText="Close" />
          <ModalDialogHeader>
            <ModalDialogTitle>Single Order Input</ModalDialogTitle>
          </ModalDialogHeader>
          <ModalDialogBody>
          {/* Order Number Input */}
          <TextInputWithFloatingLabel mt={4}>
            <TextInput
              size="mini"
              placeholder="Order Number"
              onChange={event => {
                setOrderNumber(event.target.value);
              }}
              value={orderNumber}
              data-testid="order-number-input"
            />
            <TextInputFloatingLabel>
              Enter Order Number
            </TextInputFloatingLabel>
            </TextInputWithFloatingLabel>
            
            {/* Order Number Input */}
            <TextInputWithFloatingLabel mt={4}>
              <TextInput
                size="mini"
                placeholder="Item ID"
                onChange={event => {
                  setItemId(event.target.value);
                }}
                value={itemId}
                data-testid="item-id-input"
              />
              <TextInputFloatingLabel>
                Enter Item ID
              </TextInputFloatingLabel>
            </TextInputWithFloatingLabel>

          </ModalDialogBody>
          <ModalDialogButtons mt={5}>
            <Button skin="secondary" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button skin="primary" onClick={() => generateData()}>
              OK
            </Button>
          </ModalDialogButtons>
        </ModalDialogContent>
      </ModalDialog>
    </>
  )
}

export default SingleOrderInput;