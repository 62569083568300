import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AlertBox,
  AlertBoxDismissButton,
  Column,
  Divider,
  H4,
  Row,
  Table,
  TableScrollContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TableTextNewLine,
  TableCaption,
  Typography,
} from '@vp/swan';
import { useFileData } from '../state/FileDataContexts';
import AttributesDisplay from "./AttributesDisplay.js";
import { getMergedAttributes } from "../apis/omsApi";
import { transformAttributesListToObj } from "../apis/apiUtils";

const ROW_BORDER_STYLE = { border: '1px solid gray' };

/**
 * Preview of remediation display.
 */
const PreviewTable = ({
  title,
  bottomMargin,
  isVisible,
  setVisible,
}) => {

  const fileData = useFileData();
  const hasData = fileData && fileData.length > 0 && fileData[0].itemId;

  // If any are selected, display those.  If none selected, display all.
  let displayedData = fileData ? fileData.filter(o => o.isSelected) : [];
  if (displayedData.length === 0) {
    displayedData = fileData;
  }

  return (
    <>
      {isVisible && hasData && (
        <>
          <AlertBox
            skin="positive"
            dismissed={!isVisible}
            onRequestDismiss={() => {
              setVisible(false);
            }}
            style={{ color: "black" }}
          >
            <Row>
              <Column span={12}>
                <TableScrollContainer>
                  <Table skin="simple">
                    <TableCaption>
                      <H4>{title}</H4>
                    </TableCaption>
                    <TableHead>
                      <TableRow style={ROW_BORDER_STYLE}>
                        <TableCell>Order Number</TableCell>
                        <TableCell>Item ID</TableCell>
                        <TableCell>SKU</TableCell>
                        <TableCell>SKU version</TableCell>
                        <TableCell>Product Key</TableCell>
                        <TableCell>Product version</TableCell>
                        <TableCell>Order State</TableCell>
                        <TableCell>Item State</TableCell>
                        <TableCell>New Doc URL</TableCell>
                        <TableCell>Updated Variable Attributes</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displayedData &&
                        displayedData.length > 0 &&
                        displayedData.map((o, i) => (
                          <TableRow style={ROW_BORDER_STYLE}>
                            <TableCell>{o.orderNumber}</TableCell>
                            <TableCell>
                              <TableTextNewLine>{o.itemId}</TableTextNewLine>
                            </TableCell>
                            <TableCell>
                              <TableTextNewLine>
                                {o.newSku || o.fulfillmentSku}
                              </TableTextNewLine>
                            </TableCell>
                            <TableCell>
                              <TableTextNewLine>
                                {o.newSkuVersion || o.skuVersion}
                              </TableTextNewLine>
                            </TableCell>
                            <TableCell>
                              <TableTextNewLine>
                                {o.newProductKey || o.productKey}
                              </TableTextNewLine>
                            </TableCell>
                            <TableCell>
                              <TableTextNewLine>
                                {o.newProductVersion || o.productVersion}
                              </TableTextNewLine>
                            </TableCell>
                            <TableCell>
                              <TableTextNewLine>
                                {o.orderState}
                              </TableTextNewLine>
                            </TableCell>
                            <TableCell>
                              <TableTextNewLine>{o.itemState}</TableTextNewLine>
                            </TableCell>
                            <TableCell>
                              <TableTextNewLine>
                                {o.newDocSrcUrl || "-"}
                              </TableTextNewLine>
                            </TableCell>
                            <TableCell>
                              <AttributesDisplay
                                attributes={transformAttributesListToObj(
                                  getMergedAttributes(
                                    o.variableAttributes,
                                    o.changedAttributes ? o.changedAttributes : o.newVariableAttributes,
                                    o.deletedVariableAttributes
                                  )
                                )}
                                areNewAttrs={false}
                                newAttributes={o.changedAttributes||o.newVariableAttributes}
                                deletedAttributes={o.deletedVariableAttributes}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableScrollContainer>
              </Column>
            </Row>
            <AlertBoxDismissButton
              accessibleText="Dismiss preview"
              visuallyHiddenLabel={false}
            />
          </AlertBox>
          <Row>
            <Divider mb={bottomMargin} />
          </Row>
        </>
      )}
    </>
  );
}

PreviewTable.propTypes = {
  title: PropTypes.string,
  bottomMargin: PropTypes.number,
  isVisible: PropTypes.bool,
  setVisible: PropTypes.func,
};

export default PreviewTable;
