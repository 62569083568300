import { observableAxiosClient } from './axios/observableAxiosClient';
import getIdTokenAndShopperId from './auth';
import {
  createApiError,
  createStandardHeaders,
  transformAttributesObjToList,
  TIMEOUT_MS,
} from './apiUtils';
import { PRODUCTION_ENV, STAGING_ENV } from '../util/orderUtils';

const DALI_BASE_URL_PROD = 'https://dali.eu-west-1-igopogo.orders.vpsvc.com';
const DALI_BASE_URL_STAGING = 'https://dali-staging.orders.vpsvc.com';

/**
 * Translate an environment name to a URL.
 * @param {*} envName 
 * @returns 
 */
export const getEnvUrl = (envName) => {
  if (PRODUCTION_ENV === envName) {
    return DALI_BASE_URL_PROD;
  }
  if (STAGING_ENV === envName) {
    return DALI_BASE_URL_STAGING;
  }
  // Or throw an error here?
  return DALI_BASE_URL_PROD;
}

/**
 * Get an order from DALI.
 */
export async function getOrderViaDali(env, orderNumber) {
  const { shopperId, idToken } = getIdTokenAndShopperId();

  if (!shopperId || !orderNumber) {
    throw createApiError('getOrderViaDali', 'Required Shopper ID and/or Order Number is empty');
  }
  const baseUrl = getEnvUrl(env);
  const NO_ORDER = {
    orderNumber,
    lineItems: [],
    state: 'Unknown',
    shippingAddress: {
      country: 'Unknown'
    }
  };

  try {
    const response = await observableAxiosClient({
      url: `${baseUrl}/api/orders/${orderNumber}`,
      method: 'GET',
      headers: createStandardHeaders(idToken),
      timeout: TIMEOUT_MS
    });
    const order = response.data;
    const o = order || NO_ORDER;
    return o;
  } catch (err) {
    trackError(err, { errorName: 'order-fetch-dali' });
    throw err;
  }
}

/**
 * NOTE: Originally used with the DALi /api/search endpoint.
 * DALI order data from the search endpoint looks a little different from that from OMS.
 * Normalize the DALI order to the OMS format for this app.
 * @param {*} o Order
 */
const daliToOmsOrder = (o) => {
  const items = o.lineItems;
  for (const item of items) {
    item.id = item._id;
    item.fulfillmentSku = item.fulfillmentData.fulfillmentSku;
    item.fulfillmentSkuVersion = item.fulfillmentData.fulfillmentSkuVersion;
    item.productKey = item.productData.productKey;
    item.productVersion = item.productData.productVersion;
    item.documentUrl = item.designData.docRefUrl;
    delete item.fulfillmentHolds;
    item.variableAttributes = transformAttributesObjToList(item.fulfillmentData.fulfillmentOptions);
  }
  return o;
}