import { trackError } from '@vp/om-newrelic-tracker';
import { observableAxiosClient } from './axios/observableAxiosClient';
import getIdTokenAndShopperId from './auth';
import {
  createStandardHeaders,
  createPostHeaders,
  createApiError,
  createUiWarning,
  transformAttributesObjToList,
  TIMEOUT_MS,
} from './apiUtils';
import { PRODUCTION_ENV, STAGING_ENV, mergeOrderData, mergeAllOrderItems } from '../util/orderUtils';
import { getOrderViaDali } from './daliApi';

const OMS_BASE_URL_PROD = 'https://ordermanagement.orders.vpsvc.com';
const OMS_BASE_URL_STAGING = 'https://ordermanagement-staging.orders.vpsvc.com';

/**
 * Translate an environment name to a URL.
 * @param {*} envName 
 * @returns 
 */
export const getEnvUrl = (envName) => {
  if (PRODUCTION_ENV === envName) {
    return OMS_BASE_URL_PROD;
  }
  if (STAGING_ENV === envName) {
    return OMS_BASE_URL_STAGING;
  }
  // Or throw an error here?
  return OMS_BASE_URL_PROD;
}

/**
 * Get an order from OMS.
 * NOTE: Currently replaced by call to DALi.
 */
export async function getOrder(env, orderNumber) {
  const { shopperId, idToken } = getIdTokenAndShopperId();

  if (!shopperId || !orderNumber) {
    throw createApiError('getOrder', 'Required Shopper ID and/or Order Number is empty');
  }
  const baseUrl = getEnvUrl(env);

  try {
    const response = await observableAxiosClient({
      url: `${baseUrl}/api/v2/orders/${orderNumber}`,
      method: 'get',
      headers: createStandardHeaders(idToken),
      timeout: TIMEOUT_MS,
    });
    return response.data;
  } catch (err) {
    trackError(err, { errorName: 'order-fetch' });
    throw err;
  }
}

/**
 * Fetch all orders from OMS.
 * @param {string} env Environment string.
 * @param {*} orderArr Array of order/item row objects.
 * @param {boolean} isRefresh If true, this is a refresh call.
 * @param {boolean} doMerge If false, do not merge row data.  Default is true.
 * @returns Array of new Order rows with more properties from OMS.
 */
export async function getOrders(env, orderArr, isRefresh, doMerge = true) {
  if (orderArr.length === 0) {
    return;
  }
  const newEntries = [];
  newEntries.meta = orderArr.meta; // Copy meta info set by Validator.
  const isOrderOnlyMode = orderArr.meta.isOrderOnlyMode;
  for (let i = 0; i < orderArr.length; i++) {
    const row = orderArr[i];
    const { orderNumber, itemId } = row;
    if (!orderNumber) {
      // eslint-disable-next-line no-continue
      continue;
    }
    if (!isOrderOnlyMode && !itemId) {
      // eslint-disable-next-line no-continue
      continue;
    }
    try {
      const o = await getOrderViaDali(env, orderNumber);
      if (isOrderOnlyMode) {
        const newItemEntries = mergeAllOrderItems(o, isRefresh, doMerge);
        newItemEntries.forEach((r) => newEntries.push(r));
      } else {
        const newEntry = mergeOrderData(o, row, isRefresh, doMerge);
        newEntries.push(newEntry);
      }
    } catch (error) {
      console.log(`Got API Error getting order ${orderNumber}`);
      throw error;
    }
  }
  // Now that we've loaded all items, take this out of orderOnlyMode for refresh operations.
  if (isOrderOnlyMode) {
    newEntries.meta.isOrderOnlyMode = false;
  }
  return newEntries;
};

/**
 * Update products in a set of items in an order using OMS.
 * @param {string} env Environment string.
 * @param {string} orderNumber Required order number.
 * @param {string} itemId Required item ID of product to update.
 * @param {object} updateData object with fields indicating updated attributes.
 * @returns
 */
export async function updateOrder(env, orderNumber, itemId, updateData) {
  const { shopperId, idToken } = getIdTokenAndShopperId();

  if (!shopperId || !orderNumber) {
    const msg = !orderNumber ? 'Missing order number' : 'Empty shopper ID';
    throw createApiError('updateOrder', msg);
  }

  if (!itemId || !updateData) {
    const msg = !itemId ? 'Missing item ID' : 'No update data object';
    throw createApiError('updateOrder', msg);
  }

  // eslint-disable-next-line no-use-before-define
  const postData = buildUpdateOrderData(itemId, updateData);
  if (!postData) {
    // No changes to post!  Skip the API call.
    return {};
  }
  const hdrs = createPostHeaders(idToken);
  const baseUrl = getEnvUrl(env);

  try {
    const response = await observableAxiosClient({
      url: `${baseUrl}/api/v2/orders/${orderNumber}/update/product`,
      method: 'post',
      data: postData,
      headers: hdrs,
      timeout: TIMEOUT_MS,
    });
    return response.data;
  } catch (err) {
    trackError(err, { errorName: 'oms-api.order-update' });
    throw err;
  }
}

/**
 * Update document URL for an item in an order.
 * @param {string} env Environment string.
 * @param {string} orderNumber Required order number.
 * @param {string} itemId Required item ID of product to update.
 * @param {string} newDocUrl Required updated document URL.
 * @param {string} origDocUrl Original document URL.
 * @param {string} origLivePreviewUrl Original live preview URL.
 * @returns
 */
export async function updateOrderItemDocumentUrl(env, orderNumber, itemId, newDocUrl, origDocUrl, origLivePreviewUrl) {
  const { shopperId, idToken } = getIdTokenAndShopperId();

  if (!shopperId || !orderNumber) {
    const msg = !orderNumber ? 'Missing order number' : 'Empty shopper ID';
    throw createApiError('updateOrderItemDocumentUrl', msg);
  }

  if (!itemId || !newDocUrl) {
    const msg = !itemId ? 'No item ID' : 'No doc URL';
    throw createApiError('updateOrderItemDocumentUrl', msg);
  }

  const postData = [{
    lineItemId: itemId,
    docRefUrl: newDocUrl,
    livePreviewUrl: origLivePreviewUrl,
    originalDocRefUrl: origDocUrl,
  }];
  const hdrs = createPostHeaders(idToken);
  const baseUrl = getEnvUrl(env);

  try {
    const response = await observableAxiosClient({
      url: `${baseUrl}/api/v2/orders/${orderNumber}/update/document?changeRequestInitiator=INTERNAL`,
      method: 'post',
      data: postData,
      headers: hdrs,
      timeout: TIMEOUT_MS,
    });
    return response.data;
  } catch (err) {
    trackError(err, { errorName: 'oms-api.order-update-doc' });
    throw err;
  }
}

/**
 * Resubmit
 * @param {*} env Envirenment string
 * @param {*} orderNumber 
 * @param {*} reason Reason for resubmission
 * @returns 
 */
export async function resubmitOrder(env, orderNumber, reason) {
  const { shopperId, idToken } = getIdTokenAndShopperId();

  if (!shopperId || !orderNumber) {
    const msg = !orderNumber ? 'Missing order number' : 'Empty shopper ID';
    throw createApiError('resubmitOrder', msg);
  }

  if (!reason) {
    throw createApiError('resubmitOrder', 'Remediation Reason field is missing.');
  }

  const baseUrl = getEnvUrl(env);

  try {
    const response = await observableAxiosClient({
      url: `${baseUrl}/api/v2/orders/${orderNumber}/resubmit?reason=${reason}&agent=${shopperId}`,
      method: 'post',
      headers: createPostHeaders(idToken),
      timeout: TIMEOUT_MS,
    });
    return response.data;
  } catch (err) {
    trackError(err, { errorName: 'oms-api.order-resubmit' });
    throw err;
  }
}

/**
 *  Replace items in an order using OMS.
 * @param {string} env
 * @param {*} orderNumber 
 * @param {*} itemId 
 * @param {*} quantity 
 * @param {*} reason 
 * @returns 
 */
export async function replaceOrder(env, orderNumber, itemId, quantity, reason) {
  const { shopperId, idToken } = getIdTokenAndShopperId();

  if (!shopperId || !orderNumber || !itemId) {
    const msg = !shopperId ? 'Missing Shopper ID' : (!orderNumber ? 'Empty order number' : 'Empty Item ID');
    throw createApiError('replaceOrder', msg);
  }

  if (!reason) {
    throw createApiError('replaceOrder', 'Remediation Reason field is missing');
  }

  // eslint-disable-next-line no-use-before-define
  const replacementData = buildReplaceOrderData(itemId, quantity, reason);
  const baseUrl = getEnvUrl(env);

  try {
    const response = await observableAxiosClient({
      url: `${baseUrl}/api/v2/orders/${orderNumber}/replace?replacedBy=${shopperId}`,
      method: 'post',
      data: replacementData,
      headers: createPostHeaders(idToken),
      timeout: 10000,
    });
    return response.data;
  } catch (err) {
    trackError(err, { errorName: 'oms-api.order-replace' });
    throw err;
  }
}

// ============= UTILS =============

/** Build post data for the ReplaceOrder API call. */
const buildReplaceOrderData = (itemId, quantity, reason) => {
  const orderArr = [];
  const newOrder = {
    customReplacementData: {},
    lineItemId: itemId,
    quantity,
    replacementReason: reason,
  };
  orderArr.push(newOrder);
  return orderArr;
};

const hasAnyAttributeChanges = (changedAttrsObj, deletedAttrList) => {
  // Nothing has changed
  if (!changedAttrsObj && !deletedAttrList) {
    return false;
  }
  return true;
}

/**
 *  Build post data for UpdateOrder API call.
 * @param {*} itemId 
 * @param {*} changedData 
 * @returns A JSON string to be used as Post data, or undefined if there were no changes.
 */
const buildUpdateOrderData = (itemId, changedData) => {
  const postData = {
    lineItemId: itemId,
  };
  let appliedAnyChanges = false;
  // Check for new global Sku version.
  if (changedData.newSkuVersion) {
    appliedAnyChanges = true;
    postData.fulfillmentSkuVersion = changedData.newSkuVersion;
  }
  // Check for global new sku.
  if (changedData.newSku) {
    appliedAnyChanges = true;
    postData.fulfillmentSku = changedData.newSku;
  }
  // Check for global new product key.
  if (changedData.newProductKey) {
    appliedAnyChanges = true;
    postData.productKey = changedData.newProductKey;
  }
  // Check for global new product version.
  if (changedData.newProductVersion) {
    appliedAnyChanges = true;
    postData.productVersion = changedData.newProductVersion;
  }
  //Changes from the tool are in changedAttributes, whereas changes from the file are in newVariableAttributes.
  //It is assumed that we cannot have both, ie changes are either from the file or from the tool.
  const changedAttributesObj = changedData.changedAttributes
    ? changedData.changedAttributes
    : changedData.newVariableAttributes;

  if (
    !appliedAnyChanges &&
    !hasAnyAttributeChanges(
      changedAttributesObj,
      changedData.deletedVariableAttributes
    )
  ) {
    // No changes at all!  Skip the API call.
    return undefined;
  }

  // Get merged variable attributes.
  const attrArray = getMergedAttributes(
    changedData.variableAttributes,
    changedAttributesObj,
    changedData.deletedVariableAttributes
  );
  postData.variableAttributes = attrArray;
  const dataArr = [];
  dataArr.push(postData);
  return JSON.stringify(dataArr);
};


/**
 * Merge current attributes with changed & deleted attributes to build a single list
 * of attributes suitable for sending to OMS API.  Deletion has priority.
 * @param {*} curAttrsObj Current attributes, typically from OMS.
 * @param {array} changedAttrsObj Additional attributes or changed attribute values, from UI or file.
 * @param {array} deletedAttrList List of deleted attributes.
 * @returns an array of colon-separated strings of the form 'name:value'.
 */
export const getMergedAttributes = (curAttrsObj, changedAttrsObj, deletedAttrList) => {
  // We have no current attributes.
  if (!curAttrsObj) {
    return [];
  }
  // Nothing has changed
  if (!changedAttrsObj && !deletedAttrList) {
    const sameAttrList = transformAttributesObjToList(curAttrsObj);
    return sameAttrList;
  }
  
  // Guard against falsey inputs.
  const deletedList = deletedAttrList ? deletedAttrList : [];
  if (!changedAttrsObj) {
    changedAttrsObj = {};
  }
  
  const curAttrNames = Object.getOwnPropertyNames(curAttrsObj);
  const finalAttrList = [];
  // Handle changes to existing attribute values.
  curAttrNames.forEach((attrName) => {
    if (deletedList.includes(attrName)) {
      // Deleting this attribute, so do not add it to our final list.
      return;
    }
    let val = curAttrsObj[attrName];
    if (changedAttrsObj.hasOwnProperty(attrName)) {
      val = changedAttrsObj[attrName];
    }
    finalAttrList.push(attrName + ":" + val);
  });
  // Now handle new attribute values.
  const changedAttrNames = Object.getOwnPropertyNames(changedAttrsObj);
  changedAttrNames.forEach((attrName) => {
    // This is a little weird: we have a deleted attribute but it also is a new attribute...
    if (deletedList.includes(attrName)) {
      // Deleting this attribute, so do not add it to our final list.
      return;
    }
    if (!curAttrsObj.hasOwnProperty(attrName)) {
      // New attribute
      let val = changedAttrsObj[attrName];
      finalAttrList.push(attrName + ":" + val);
    }
  });
  return finalAttrList;
};
