import React from 'react';
import {
  Button,
  BasicCollapsible,
  Spinner,
  Typography,
  StandardSection,
  Card,
  AlertBox,
  Row,
  Column,
  FlexBox,
} from '@vp/swan';
import { useTranslations } from '@vp/om-translations';
import useIdentityContext from '../../hooks/useIdentityContext';

/** To include auth debugging info, change this to 'true'. */
const INCLUDE_AUTH_INFO = false;

const DevHomeSignInPanel = () => {
  const { isIdentityInitialized, identity, auth } = useIdentityContext();
  const {
    isShopper,
    isInternalUser,
    isAnonymousUser,
    isSignedIn,
    shopperId,
    cimpressADFSUserId,
    anonymousUserId,
    wasShopperId,
  } = identity;
  const [a11yLabels] = useTranslations(['a11yLabels']);
  const tok = auth ? auth.getToken() : 'None';

  if (!isIdentityInitialized) {
    return (
      <FlexBox justifyContent="center">
        <Spinner
          accessibleText={a11yLabels.preloader}
          aria-busy="true"
          aria-live="polite"
          role="status"
        />
      </FlexBox>
    );
  }

  return (
    <StandardSection
      backgroundColor="white"
      style={{ border: '1px solid black' }}
      styleReason="Development UI"
      gutterBottom="mini"
    >
      <Card>
        {isShopper && (
          <AlertBox skin="positive" margin={1}>
            <Typography> Shopper ID: {shopperId} </Typography>
          </AlertBox>
        )}
        {isInternalUser && (
          <AlertBox skin="positive"  margin={1}>
            <Typography>Greetings {cimpressADFSUserId}! 👋</Typography>
          </AlertBox>
        )}

        {isAnonymousUser && (
          <AlertBox skin="warning"  margin={1}>
            <Typography>Session is currently anonymous.</Typography>
            <Typography>CanonicalID: {anonymousUserId}</Typography>
          </AlertBox>
        )}
        {INCLUDE_AUTH_INFO && (
          <BasicCollapsible skin="standard" heading="Auth Session Metadata">
            <Typography>Signed In: {isSignedIn ? 'true' : 'false'}</Typography>
            <Typography>
              Identity is Shopper: {isShopper ? 'true' : 'false'}
            </Typography>

            <Typography>
              Identity is CimpressADFS User: {isInternalUser ? 'true' : 'false'}
            </Typography>
            <Typography>
              Identity is Anonymous: {isAnonymousUser ? 'true' : 'false'}
            </Typography>
            {wasShopperId && (
              <Typography>
                Was Anonymous Shopper:
                <Typography
                  component="span"
                  backgroundColor="light-grey"
                  m="m"
                  p="s"
                >
                  {wasShopperId}
                </Typography>
              </Typography>
            )}
            {tok && (
              <Typography>
                JWT: {`${tok.substring(0, 25)}...${tok.substring(25, -100)}`}
              </Typography>
            )}
          </BasicCollapsible>
        )}
        <Row>
          {identity.isSignedIn ? (
            <Column span={12}>
              <Button
                skin="primary"
                width="full-width"
                onClick={() => auth.signOut()}
                type="button"
              >
                Sign Out
              </Button>
            </Column>
          ) : (
            <Column span={12}>
              <Button
                skin="primary"
                width="full-width"
                onClick={() => auth.signIn()}
                type="button"
              >
                Sign In
              </Button>
            </Column>
          )}
        </Row>
      </Card>
    </StandardSection>
  );
};

export default DevHomeSignInPanel;
