import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import activityTypes from './activityTypes';

const LoggingDispatchContext = createContext(); // conxtext for the write-only dispatch
const LoggingDataContext = createContext(); // context for the read-only error state

/**
 * Activity log context provider that logs messages in ActivityLogs text area.
 * @param {*} initialStateOverride (Optional) Test only, set up initial state with given object, for testing only
 */
export const ActivityLoggerProvider = ({ children, initialStateOverride }) => {
  const defaultInitialState = {
    activities: [],
  };

  const reducer = (state, action) => {

    switch (action.type) {
      case activityTypes.CLEAR_LOGS: {
        return defaultInitialState;
      }
      default: {
        const timestamp = new Date().toJSON().replace(/T/g, " ").replace(/Z/g, "").split('.')[0];
        const newActivity = {
          timestamp,
          type: action.type,
          message: action.message,
        };

        const newState = {
          activities: [...state.activities, newActivity],
        };
        return newState;
      }
    }
  };

  const initialState = initialStateOverride || defaultInitialState;

  const [activityLoggerState, activityLoggerDispatch] = useReducer(
    reducer,
    initialState
  );

  return (
    <LoggingDataContext.Provider value={activityLoggerState}>
      <LoggingDispatchContext.Provider value={activityLoggerDispatch}>
        {children}
      </LoggingDispatchContext.Provider>
    </LoggingDataContext.Provider>
  );
};

/**
 * Custom hook exporting activity logger state
 * @example
 * const { activityLoggerState } = useActivityLoggerState();
 * @example
 * activityLoggerState: {
 *  activities: [
 *    timestamp: String,
 *    type: String,
 *    message: String,
 * ]
 * }
 */
export const useActivityLoggerState = () => {
  const activityLoggerState = useContext(LoggingDataContext);
  if (activityLoggerState === undefined) {
    throw new Error(
      "useActivityLoggerState must be used within ActivityLoggerProvider!"
    );
  }
  return activityLoggerState;
};

/**
 * Custom hook exporting activity logger dispatch.
 * @example
 * const { activityLoggerDispatch } = useActivityLoggerDispatch();
 * @param {*} action Dispatch action. See example below
 * @example
 * {
 *  type(required): 'FILE'
 * }
 */
export const useActivityLoggerDispatch = () => {
  const activityLoggerDispatch = useContext(LoggingDispatchContext);
  if (activityLoggerDispatch === undefined) {
    throw new Error(
      "useActivityLoggerDispatch must be used within ActivityLoggerProvider!"
    );
  }
  return activityLoggerDispatch;
};

ActivityLoggerProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  initialStateOverride: PropTypes.object,
};
