import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  BasicCombobox,
  Button,
  ComboboxOption,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Dropdown,
  DropdownOption
} from "@vp/swan";
import { fetchProductAttributes } from "../apis/selectorApi.js";
import { useFileDataDispatch, useFileData } from "../state/FileDataContexts.js";
import actionTypes from "../state/actionTypes.js";
import { useErrorNotifierDispatch } from './errors/ErrorNotifier.js';
import { createApiError } from '../apis/apiUtils.js';
import errorTypes from './errors/errorTypes.js';

const isOptionDisabled = (selectedAttributes, attributeName, attributeVal) => {
  return selectedAttributes[attributeName] === attributeVal;
};

const createSelectorApiError = (caller, msg) => {
  const apiErr = createApiError(caller, msg);
  apiErr.type = errorTypes.SELECTOR_API;
  return apiErr;
}

const NO_WRAP_STYLE = { 'white-space': 'nowrap' };
const STRIKE_THRU_STYLE = {
  "text-decoration": "line-through",
  "text-decoration-thickness": "3px",
  "text-decoration-color": "#D24345"
};

// Fetch an item's product variable attributes, and merge item's selections with these attrs.
const fetchProductAttributeSet = async (item) => {
  console.log(`Get Product Attributes for ${JSON.stringify(item)}`);

  const productAttributes = await fetchProductAttributes(
    item.fulfillmentSku,
    item.skuVersion,
    null, //item.variableAttributes: There is no need to send initial selection values.
    item.country
  );

  // merge attrs from API with item.variableAttributes.
  const attributeNames = Object.getOwnPropertyNames(item.variableAttributes);
  attributeNames.forEach((name, i) => {
    for (var i = 0; i < productAttributes.length; i++) {
      const attr = productAttributes[i];
      if (attr.displayName === name) {
        attr.selectedValue = item.variableAttributes[name];
        return;
      }
    }
  });
  return productAttributes;
};

/**
 * Mark an attribute with 'isDeleted = true' property. 
 * @param {string} attrName Attribute name to mark.
 * @param {array} attrs Array of attributes
 * @param {boolean} isDeleted Value to mark property.
 * @returns New copy of attribute array suitable for using for 'setState'.
 */
const markAttrDeleted = (attrName, attrs, isDeleted) => {
  const newAttrs = [];
  for (var a of attrs) {
    if (a.displayName === attrName) {
      const newAttr = { ...a, isDeleted: isDeleted };
      newAttrs.push(newAttr);
    } else {
      newAttrs.push(a);
    }
  }
  return newAttrs;
}


/**
 * Display current Variable Attributes for this item's product.
 * @param {*} param0 
 * @returns 
 */
const VariableAttributes = ({setChangedValueFn, setDeletedFn}) => {
  const [attributes, setAttributes] = useState([]);
  const [changedAttributes, setChangedAttributes] = useState({});
  const [deletedAttributes, setDeletedAttributes] = useState([]);
  const [fetchAttrMessage, setFetchAttrMessage] = useState(undefined);
  
  const dispatch = useFileDataDispatch();
  const fileData = useFileData();
  const { errorNotifierDispatch } = useErrorNotifierDispatch();

  const selectedItem = fileData.length > 0 ? fileData[0] : {};

  const onGetAttributes = async (item) => {
    try {
      setFetchAttrMessage(undefined);
      const productAttributes = await fetchProductAttributeSet(item);
      setAttributes(productAttributes);
      if (productAttributes.length === 0) {
        setFetchAttrMessage('No product attributes found.');
      }
    } catch (error) {
      console.log(`Got API Error ${error.message}`);
      errorNotifierDispatch(createSelectorApiError("FetchAttributes", `Request failed with status ${error.errorStatusCode}`));
    }
  }

  const onChangeAttributes = (attributeName, value) => {
    console.log(
      `Preupddate changed attributes: ${JSON.stringify(changedAttributes)}`
    );
    const newAttributes = { ...changedAttributes };
    
    if (!value) {
      delete newAttributes[attributeName];
    } else {
      newAttributes[attributeName] = value;
    }

    setChangedAttributes(newAttributes);
    setChangedValueFn(newAttributes);
    
    dispatch({
      type: actionTypes.ATTRIBUTES_CHANGED,
      changedAttributes: newAttributes,
    });
  };

  const onClearValue = (attributeDisplayName) => {
    onChangeAttributes(attributeDisplayName, "");
  };

  /** Implements a toggle switch for delete. */
  const onClickRemoveAttr = (attributeDisplayName) => {
    const hasAttr = deletedAttributes.includes(attributeDisplayName);
    let newList = [];
    if (hasAttr) {
      // 'Deleted' is checked, so remove it
      newList = deletedAttributes.filter(n => n !== attributeDisplayName);
      setAttributes(markAttrDeleted(attributeDisplayName, attributes, false));
    } else {
      // 'Deleted' is not checked, so add it.
      newList = [...deletedAttributes];
      newList.push(attributeDisplayName);
      setAttributes(markAttrDeleted(attributeDisplayName, attributes, true));
    }
    setDeletedAttributes(newList);
    
    setDeletedFn(newList);
    dispatch({
      type: actionTypes.ATTRIBUTES_DELETED,
      deletedAttributes: newList,
    });
  }

  return (
    <>
      <Button size="mini" onClick={() => onGetAttributes(selectedItem)} mb={3}>
        Fetch Product Attributes
      </Button>
      {fetchAttrMessage && <Typography>{fetchAttrMessage}</Typography>}
      <Table skin="simple">
        <TableHead>
          <TableRow>
            <TableCell style={NO_WRAP_STYLE}>Attribute Name</TableCell>
            <TableCell style={NO_WRAP_STYLE}>Select Value...</TableCell>
            <TableCell style={NO_WRAP_STYLE}>Clear Value</TableCell>
            <TableCell style={NO_WRAP_STYLE}>Remove Attribute</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attributes &&
            selectedItem &&
            attributes.map((a, i) => (
              <TableRow key={i}>
                {/* Name */}
                <TableCell textAlign="left">
                  {a.isDeleted ? (
                    <Typography style={STRIKE_THRU_STYLE}>
                      {a.displayName}
                    </Typography>
                  ) : (
                    <Typography>{a.displayName}</Typography>
                  )}
                </TableCell>
                {/* Edited Value */}
                <TableCell>
                  <Dropdown
                    fullWidth
                    size="mini"
                    onChange={(event) =>
                      onChangeAttributes(a.displayName, event.target.value)
                    }
                    disabled={a.isDeleted ? true : false}
                    value={changedAttributes[a.displayName] || ""}
                  >
                    <DropdownOption key="" value=""></DropdownOption>
                    {a.attrValues.map((val, index) => (
                      <DropdownOption key={index} value={val}>
                        {val}
                      </DropdownOption>
                    ))}
                  </Dropdown>
                </TableCell>
                {/* Clear Value */}
                <TableCell>
                  <Button
                    size="mini"
                    onClick={() => onClearValue(a.displayName)}
                    disabled={changedAttributes[a.displayName] ? false : true}
                  >
                    Clear
                  </Button>
                </TableCell>
                {/* Remove Attribute */}
                <TableCell>
                  <Button
                    size="mini"
                    onClick={() => {
                      onClickRemoveAttr(a.displayName);
                      onClearValue(a.displayName);
                    }}
                    disabled={false}
                  >
                    {a.isDeleted ? "Un-remove" : "Remove"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
};

VariableAttributes.propTypes = {
  setValueFn: PropTypes.func,
  setDeletedFn: PropTypes.func,
};

export default VariableAttributes;
