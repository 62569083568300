import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Column,
  FlexBox,
  Row,
  Tabs,
  TabsHeaders,
  TabHeader,
  TabsContents,
  TabContent,
  Button,
} from '@vp/swan';
import { updateOrder, updateOrderItemDocumentUrl, resubmitOrder, replaceOrder } from '../apis/omsApi';
import { useFileData } from '../state/FileDataContexts';
import { useErrorNotifierDispatch } from './errors/ErrorNotifier';
import { createApiError, createUiWarning } from '../apis/apiUtils';
import { useActivityLoggerDispatch } from "./activityLogs/ActivityLogger";
import {
  RESUBMIT_ORDERS,
  RESUBMIT_ORDER_SUCCESS,
  RESUBMIT_ORDER_FAILURE,
  REPLACE_ITEM_SUCCESS,
  REPLACE_ITEM_FAILURE,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,
  UPDATE_ITEM_DOCURL_SUCCESS,
  UPDATE_ITEM_DOCURL_FAILURE,
} from "./activityLogs/activityLoggerDispatchActions";

const BUTTON_OFFSET = 1;
const BUTTON_SPAN = 6;
const BUTTON_MARGIN = 4;

/**
 * If any file data row has a 'true' value for 'isSelected', return true; else, return false.
 * @param {*} rows Array of file data. 
 * @returns 
 */
const hasAnySelections = (rows) => {
  if (!rows || rows.length === 0) return false;
  for (var k in rows) {
    if (rows[k].isSelected) {
      return true;
    }
  }
  return false;
}

/** Does this row contain any new values to be recorded? */
const rowHasAnyChanges = (row) => {
  if (row.newDocSrcUrl ||
      row.newSku ||
      row.newSkuVersion ||
      row.newProductKey ||
      row.newProductVersion) {
        return true;
  }
  return false;
}

/**
 * Get the list of orders which have 'isSelected' set to be true.
 * @param {*} rows 
 * @returns Array of orders.
 */
const getSelectedOrders = (rows) => {
  if (!rows || rows.length === 0) {
    return [];
  }
  const selectedOrders = [];
  for (var k in rows) {
    const order = rows[k];
    if (order.isSelected === true) {
      selectedOrders.push(order);
    }
  }
  return selectedOrders;
}
  
/**
 * Tab Section containing remediation buttons.
 */
const AllOrSelectedTabs = ({ remediationReason }) => {
  const [isUpdateAllDisabled, setIsUpdateAllDisabled] = useState(true);
  const [isResubmitAllDisabled, setIsResubmitAllDisabled] = useState(true);
  const [isUpdateResubmitAllDisabled, setIsUpdateResubmitAllDisabled] =
    useState(true);
  const [isReplaceAllDisabled, setIsReplaceAllDisabled] = useState(true);
  
  const fileData = useFileData();
  const anySelections = hasAnySelections(fileData);
  const { errorNotifierDispatch } = useErrorNotifierDispatch();
  const activityLoggerDispatch = useActivityLoggerDispatch();
  const env = fileData.meta.environment;

    /** Reset state of buttons when file data has changed. */
  useEffect(() => {
    const isFileEmpty = !(fileData.length && fileData.length > 0);
    setIsUpdateAllDisabled(isFileEmpty);
    setIsResubmitAllDisabled(isFileEmpty);
    setIsReplaceAllDisabled(isFileEmpty);
    setIsUpdateResubmitAllDisabled(isFileEmpty);
  }, [fileData]);

  /** Format and display an array of errors. */
  const displayErrors = (callerName, errArr) => {
    if (errArr.length > 0) {
      const msg = errArr.map(err => {
        if (err.errorStatusCode) {
          return `Request failed with status ${err.errorStatusCode}`;
        } else if (err.localReason) {
          return `Request failed because ${err.localReason}`;
        } else {
          return `Request failed because ${err.message}`;
        }
      }).join(', ');
      errorNotifierDispatch(createApiError(callerName, msg));
    }
  };

  /** Format and display a warning message. */
  const displayWarning = (callerName, msg) => {
    errorNotifierDispatch(createUiWarning(callerName, msg));
  }

  /** Generic check that any orders exist.  If none, generate a warning. */
  const hasAnyOrders = (caller, orderList) => {
    if (!orderList || orderList.length === 0) {
      errorNotifierDispatch(createUiWarning(caller, 'There are no orders to take action on!'));
      return false;
    }
    return true;
  }
   /******************** UPDATE *********************** */
  /** Call update on each order row and wait for all Promises to resolve. */
  const internalUpdateOrders = async (orderList) => {
    setIsUpdateAllDisabled(true);
    if (orderList.length) {
      const promiseArr = [];
      const errArr = [];

      for (var row of orderList) {
        if (!rowHasAnyChanges(row)) {
          displayWarning('updateOrder', `No changes to order ${row.orderNumber} have been made!`);
          continue;
        }
        try {
          const p = await updateOrder(env, row.orderNumber, row.itemId, row);
          promiseArr.push(p);
          activityLoggerDispatch(UPDATE_ITEM_SUCCESS(row.orderNumber, row.itemId));
        } catch (err1) {
          errArr.push(err1);
          activityLoggerDispatch(UPDATE_ITEM_FAILURE(row.orderNumber, row.itemId));
        }
        // Doc Src URL is a separate endpoint.
        if (row.newDocSrcUrl) {
          try {
            const p2 = await updateOrderItemDocumentUrl(
                        env,
                        row.orderNumber,
                        row.itemId,
                        row.newDocSrcUrl,
                        row.documentUrl,
                        row.livePreviewUrl);
            promiseArr.push(p2);
            activityLoggerDispatch(UPDATE_ITEM_DOCURL_SUCCESS(row.orderNumber, row.itemId));
          } catch (err2) {
            errArr.push(err2);
            activityLoggerDispatch(UPDATE_ITEM_DOCURL_FAILURE(row.orderNumber, row.itemId));
          }
        }
      }
      await Promise.all(promiseArr);
      displayErrors('UpdateOrders', errArr);
      setIsUpdateAllDisabled(false);
    }
  };

  /** Call update on each order in fileData. */
  const updateAllOrders = async () => {
    if (!hasAnyOrders('UpdateOrders', fileData)) {
      return;
    }
    internalUpdateOrders(fileData);
  };
  
  /**
   * Use 'isSelected' field to determine which rows to update.
   */
  const updateSelectedOrders = async () => {
    const selectedOrders = getSelectedOrders(fileData);
    if (!hasAnyOrders('UpdateSelectedOrders', fileData)) {
      return;
    }
    internalUpdateOrders(selectedOrders);
  }

  /*************** REPLACE  ******************************************/

  /** Call update on each order and wait for all Promises to resolve. */
  const internalReplaceOrders = async (orderList) => {
    if (orderList.length) {
      setIsReplaceAllDisabled(true);
      const promiseArr = [];
      const errArr = [];
      try {
        for (var row of orderList) {
          const p = replaceOrder(env, row.orderNumber, row.itemId, row.quantity, remediationReason);
          promiseArr.push(p);
          activityLoggerDispatch(REPLACE_ITEM_SUCCESS(row.orderNumber, row.itemId));
        }
        await Promise.all(promiseArr);
      } catch (err) {
        errArr.push(err);
        activityLoggerDispatch(REPLACE_ITEM_FAILURE(row.orderNumber, row.itemId));
      }
      displayErrors('ReplaceOrders', errArr);
      setIsReplaceAllDisabled(false);
    }
  };

  /**
   * Use selectionList to determine which rows to replace.
   */
  const replaceSelectedOrders = async () => {
    const selectedOrders = getSelectedOrders(fileData);
    if (!hasAnyOrders('ReplaceSelectedOrders', fileData)) {
      return;
    }
    internalReplaceOrders(orderArr);
  }

    /** Call replace on each order in fileData. */
  const replaceAllOrders = async () => {
    if (!hasAnyOrders('ReplaceOrders', fileData)) {
      return;
    }
    internalReplaceOrders(fileData);
  }; 


  /*************** RESUBMIT  ******************************************/

  /** Call resubmit on each order and wait for all Promises to resolve. */
  const internalResubmitOrders = async (orderNumberList) => {
    console.log(`Resubmitting ALL ${orderNumberList.length} distinct orders.`);
    if (orderNumberList.length) {
      setIsResubmitAllDisabled(true);
      const promiseArr = [];
      const errArr = [];
      activityLoggerDispatch(RESUBMIT_ORDERS(orderNumberList));
      try {
        for (var orderNumber of orderNumberList) {
          const p = await resubmitOrder(env, orderNumber, remediationReason);
          promiseArr.push(p);
          activityLoggerDispatch(RESUBMIT_ORDER_SUCCESS(orderNumber));
        }
        await Promise.all(promiseArr);
      } catch (err) {
        errArr.push(err);
        activityLoggerDispatch(RESUBMIT_ORDER_FAILURE(orderNumber));
      }
      displayErrors('ResubmitOrders', errArr);
      setIsResubmitAllDisabled(false);
    }
  };

  const getResubmittableOrders = (orderList) => {
    const resubmittableOrders = orderList.filter(row => row.canResubmit);
    if (resubmittableOrders.length === 0 && orderList.length > 0) {
      errorNotifierDispatch(createUiWarning('ResubmitOrder', 'No orders are eligible to be resubmitted!'));
    }
    return resubmittableOrders;
  }

  /** Call resubmit on each (distinct) order and wait for all Promises to resolve. */
  const resubmitAllOrders = async () => {
    if (!hasAnyOrders('ResubmitOrders', fileData)) {
      return;
    }
    const resubmitableOrders = getResubmittableOrders(fileData);
    const distinctOrderNumbers = new Set(
      resubmitableOrders.map(row => row.orderNumber)
    );
    const orderNumberArr = Array.from(distinctOrderNumbers);
    internalResubmitOrders(orderNumberArr);
  };

  /** Call resubmit on the (distinct) order of each row that is selected. */
  const resubmitSelectedOrders = async () => {
    const selectedOrders = getSelectedOrders(fileData);
    if (!hasAnyOrders('ResubmitSelectedOrders', fileData)) {
      return;
    }
    const resubmitableOrders = getResubmittableOrders(selectedOrders);
    const orderNumberArr = resubmitableOrders.filter(row => row.canResubmit).map(o => o.orderNumber);
    internalResubmitOrders(orderNumberArr);
  }

  /** Call update on all orders, wait to finish, then call resubmit on all. */
  const updateAndResubmitAllOrders = () => {
    setIsUpdateResubmitAllDisabled(true);
    updateAllOrders();
    resubmitAllOrders();
    setIsUpdateResubmitAllDisabled(false);
  };

  return (
    <Row>
      <Column span={6}>
        <Tabs defaultSelectedTabId="allEntries">
          <TabsHeaders>
            {anySelections && (
              <TabHeader tabId="selectedEntry" padding="3">Act on selected entry(s)</TabHeader>
            )}
            <TabHeader tabId="allEntries" padding="3">Act on all entries</TabHeader>
          </TabsHeaders>
          <TabsContents>
            {/**************** ALL ENTRIES  ********************/}
            <TabContent tabId="allEntries">
              <Row>
                <Column span={BUTTON_SPAN} offset={BUTTON_OFFSET}>
                  <FlexBox
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="stretch"
                  >
                    <Button
                      mb={BUTTON_MARGIN}
                      size="mini"
                      onClick={updateAllOrders}
                      disabled={isUpdateAllDisabled}
                    >
                      Update (only) ALL Orders
                    </Button>
                    <Button
                      mb={BUTTON_MARGIN}
                      size="mini"
                      onClick={resubmitAllOrders}
                      disabled={isResubmitAllDisabled}
                    >
                      Resubmit (only) ALL Orders
                    </Button>
                    <Button
                      mb={BUTTON_MARGIN}
                      size="mini"
                      onClick={updateAndResubmitAllOrders}
                      disabled={isUpdateResubmitAllDisabled}
                    >
                      Update & resubmit ALL Orders
                    </Button>
                    <Button
                      mb={BUTTON_MARGIN}
                      size="mini"
                      onClick={replaceAllOrders}
                      disabled={isReplaceAllDisabled}
                    >
                      Replace ALL Orders
                    </Button>
                  </FlexBox>
                </Column>
              </Row>
            </TabContent>
            {anySelections && (
              <TabContent tabId="selectedEntry">
                {/**************** SELECTED ENTRIES  ****************/}
                <Row>
                  <Column span={BUTTON_SPAN} offset={BUTTON_OFFSET}>
                    <FlexBox
                      flexDirection="column"
                      justifyContent="space-between"
                      alignItems="stretch"
                    >
                      <Button
                        mb={BUTTON_MARGIN}
                        size="mini"
                        onClick={updateSelectedOrders}
                      >
                        Update selected Order(s)
                      </Button>
                      <Button
                        mb={BUTTON_MARGIN}
                        size="mini"
                        onClick={resubmitSelectedOrders}
                      >
                        Resubmit selected Order(s)
                      </Button>
                      <Button
                        mb={BUTTON_MARGIN}
                        size="mini"
                        onClick={replaceSelectedOrders}
                      >
                        Replace selected Order(s)
                      </Button>
                    </FlexBox>
                  </Column>
                </Row>
              </TabContent>
            )}
          </TabsContents>
        </Tabs>
      </Column>
    </Row>
  );
};

AllOrSelectedTabs.propTypes = {
  fileData: PropTypes.array,
  remediationReason: PropTypes.string,
};

export default AllOrSelectedTabs;
