import actionTypes from './actionTypes';
import { EMPTY_DATA } from './FileDataContexts';

/** Copy metadata originally gleened from the Validator to the new state. */
const copyMetadata = (oldState, newState) => {
  newState.meta = oldState.meta;
}

const buildEmptyState = (action) => {
  const newRows = [];
  newRows.meta = {};
  newRows.meta.environment = action.newEnvironment;
  return newRows;
}

/**
 * Reducer for file data operations, including selections.
 * @param {*} state Current state
 * @param {*} action Action containing changes.
 * @returns 
 */
export default function fileDataReducer(state, action) {
  switch (action.type) {
    case actionTypes.ORDER_LOAD_START: {
      console.log(`Order load started from ${action.source}`);
      return state;
    }
      
    case actionTypes.ORDER_LOAD_DONE: {
      let rows = action.newData;
      console.log(`Order finished loading ${action.source}`);
      if (!rows) {
        rows = [];
      }
      return rows;
    }
      
    case actionTypes.UI_DATA_CHANGED: {
      const changedData = action.changedData;
      // changedData is an object which should be set on each fileData row.
      const newRows = [];
      for (var i = 0; i < state.length; i++) {
        const newRow = { ...state[i], ...changedData };
        newRows.push(newRow);
      }
      console.log("Order data changed: " + JSON.stringify(newRows));
      copyMetadata(state, newRows);
      return newRows;
    }
      
    case actionTypes.SELECTION_CHANGED: {
      console.log("Selection!");
      const newRows = [];
      // 'selectedRows' is an array of booleans indicating whether each row is selected.
      const selections = action.selectedRows;
      for (var i = 0; i < state.length; i++) {
        const newRow = { ...state[i], isSelected: selections[i] }
        newRows.push(newRow);
      }
      copyMetadata(state, newRows);
      return newRows;
    }
      
    case actionTypes.ATTRIBUTES_CHANGED: {
      const attributes = action.changedAttributes;
      console.log("Attributes changed: " + JSON.stringify(attributes));

      const newRows = [];
      for (var i = 0; i < state.length; i++) {
        const newRow = { ...state[i], changedAttributes: attributes }
        newRows.push(newRow);
      }
      copyMetadata(state, newRows);
      return newRows;
    }
      
    case actionTypes.ATTRIBUTES_DELETED: {
      const attributes = action.deletedAttributes;
      console.log("Attributes deleted: " + JSON.stringify(attributes));
      const newRows = [];
      for (var i = 0; i < state.length; i++) {
        const newRow = { ...state[i], deletedVariableAttributes: attributes }
        newRows.push(newRow);
      }
      copyMetadata(state, newRows);
      return newRows;
    }
      
    case actionTypes.ENVIRONMENT_CHANGED: {
      const newRows = buildEmptyState(action);
      return newRows;
    }
    
    case actionTypes.DATA_CLEAR: {
      const newRows = buildEmptyState(action);
      return newRows;
    }
      
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}