import React from "react";
import { useActivityLoggerState } from "./ActivityLogger";
import { TextArea, H4, Button, IconPolish, Row } from "@vp/swan";
import { CLEAR_LOGS } from "./activityLoggerDispatchActions";
import { useActivityLoggerDispatch } from "./ActivityLogger";
import {
  SECTION_HDR_COLOR,
  SECTION_HDR_PAD,
  SECTION_HDR_MARGIN,
  SECTION_HDR_BORDER
} from '../shared/AppStyle';

/**
 * Activity logs displaying user activity logs
 */
export const ActivityLogs = () => {
  const activityLoggerDispatch = useActivityLoggerDispatch();

  const clearLogsFn = () => {
    activityLoggerDispatch(CLEAR_LOGS());
  };

  const activityLoggerState = useActivityLoggerState();

  const activities = activityLoggerState?.activities;

  let activitiesText = "";

  for (var a of activities) {
    const t = `${a.timestamp} ${a.type} ${a.message}`;
    activitiesText = activitiesText.concat(t, "\n");
  }

  return (
    <>
      <Row
        margin={SECTION_HDR_MARGIN}
        backgroundColor={SECTION_HDR_COLOR}
        padding={SECTION_HDR_PAD}
        style={SECTION_HDR_BORDER}
      >
      <H4>
        <IconPolish />
        Activity Logs
      </H4>
      </Row>
      <Row ml={1}>
        <TextArea
          fullWidth
          resize="vertical"
          rows="10"
          placeholder="Activity logs is empty!"
          value={activitiesText}
        />
      </Row>
      <Row>
        <Button onClick={clearLogsFn} size="mini" ml={4} mt={2}>
          Clear logs
        </Button>
      </Row>
      </>
  );
};
