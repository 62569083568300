import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import {
  SwanProvider,
  SwanHead,
  SWAN_STYLE_KEY_MAP,
  SWAN_BASE_URL_MAP,
  useBrowserClasses,
  getRootClassNames,
} from '@vp/swan';

// de-dupe any styles that may be coming in from bookends.
const eagerlyLoadedSwanStyles = [
  ...new Set([
    SWAN_STYLE_KEY_MAP.accordion,
    SWAN_STYLE_KEY_MAP.alertBox,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.carousel,
    SWAN_STYLE_KEY_MAP.combobox,
    SWAN_STYLE_KEY_MAP.controlIcon,
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.hidden,
    SWAN_STYLE_KEY_MAP.icon,
    SWAN_STYLE_KEY_MAP.listbox,
    SWAN_STYLE_KEY_MAP.modalDialog,
    SWAN_STYLE_KEY_MAP.popover,
    SWAN_STYLE_KEY_MAP.progressiveImage,
    SWAN_STYLE_KEY_MAP.selectionSet,
    SWAN_STYLE_KEY_MAP.spinner,
    SWAN_STYLE_KEY_MAP.standardTile,
    SWAN_STYLE_KEY_MAP.stepIndicator,
    SWAN_STYLE_KEY_MAP.table,
    SWAN_STYLE_KEY_MAP.tabs,
    SWAN_STYLE_KEY_MAP.toggleSwitch,
    SWAN_STYLE_KEY_MAP.visible,
  ]),
];

export const SwanEnabler = props => {
  const { children, swanDomain } = props;

  const swanBaseUrl = swanDomain
    ? `${swanDomain}/swan/`
    : SWAN_BASE_URL_MAP.default.cdn; // default value, Use based on the locales
  const browserClassName = useBrowserClasses();
  const rootClassName = getRootClassNames();

  return (
    <SwanProvider swanBaseUrl={swanBaseUrl}>
      <Helmet htmlAttributes={{ class: browserClassName }} />
      <SwanHead
        renderWith={Helmet}
        renderStyleContentAsChildren
        styleSheetKeys={eagerlyLoadedSwanStyles}
      />
      <div className={rootClassName}>{children}</div>
    </SwanProvider>
  );
};

SwanEnabler.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  swanDomain: PropTypes.string.isRequired,
};
