import { transformAttributesListToObj } from '../apis/apiUtils.js';

/** Required column headers */
export const COL_ITEM_ID = 'itemId';
export const COL_ORDER_NUMBER = 'orderNumber';
/** Optional columns */
export const COL_SKU = 'newSku';
export const COL_SKU_VERSION = 'newSkuVersion';
export const COL_PRODUCT_KEY = 'newProductKey';
export const COL_PRODUCT_VERSION = 'newProductVersion';
export const COL_VARIABLE_ATTR = 'newVariableAttr'; // single attribute in file
export const COL_DELETED_VARIABLE_ATTR = 'deletedVariableAttr'; // single attribute in file
export const COL_CURRENT_VARIABLE_ATTRS = "variableAttributes"; // single attribute in file

/**List of internal props **/
export const PROP_ORDER_STATE = "orderState";
export const PROP_ITEM_STATE = "itemState";
export const PROP_COUNTRY = "country";
export const PROP_SKU = "fulfillmentSku";
export const PROP_SKU_VERSION = "skuVersion";
export const PROP_PRODUCT_KEY = "productKey";
export const PROP_PRODUCT_VERSION = "productVersion";
export const PROP_PROM_ARRIVAL_DATE = "promisedArrivalDate";
export const PROP_VARIABLE_ATTRS = "variableAttributes";
export const PROP_NEW_VARIABLE_ATTRS = 'newVariableAttributes'; // internal property for attr list
export const PROP_DELETED_VARIABLE_ATTRS = 'deletedVariableAttributes';
export const PROP_QTY = "quantity";

export const exportHeaderList = [
  COL_ORDER_NUMBER,
  COL_ITEM_ID,
  PROP_QTY,
  PROP_ORDER_STATE,
  PROP_ITEM_STATE,
  PROP_SKU,
  PROP_SKU_VERSION,
  PROP_PRODUCT_KEY,
  PROP_PRODUCT_VERSION,
  PROP_PROM_ARRIVAL_DATE,
  PROP_VARIABLE_ATTRS,
];

/** OM Environments */
export const PRODUCTION_ENV = 'Production';
export const STAGING_ENV = 'Staging';

/**
 * Extract relevant fields from an OMS order object that matches a given Item ID.
 * @param {*} omsOrder Order returned by OMS API.
 * @param {*} currentRow State including Item ID to match.
 * @param {boolean} isRefresh If true, OMS data is refreshing existing row data.
 * @param {boolean} doMerge  If false, do NOT merge current row data from UI model.
 * @returns Row entry to replace file data row.
 */
export const mergeOrderData = (omsOrder, currentRow, isRefresh, doMerge) => {
  const itemId = currentRow.itemId;
  const items = omsOrder.lineItems;
  const newEntry = {};
  newEntry.orderNumber = omsOrder.orderNumber;
  newEntry.itemId = itemId;
  if (doMerge) {
    mergeRowFields(currentRow, newEntry);
  }
  // Now find the item in the OMS order.
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item.id === itemId) {
      newEntry.quantity = item.quantity;
      const country = omsOrder.shippingAddress?.country || "US";
      const apiItemState = item.states[0].state;
      newEntry.orderState = omsOrder.state;
      newEntry.country = country;
      // Item state value could have changed on a refresh call to the API. We want to capture the change.
      if (isRefresh && apiItemState !== currentRow.itemState) {
        newEntry.itemState = currentRow.itemState;
        newEntry.updatedItemState = apiItemState;
      } else {
        newEntry.itemState = apiItemState;
      }
      newEntry.fulfillmentSku = item.fulfillmentSku;
      newEntry.skuVersion = item.fulfillmentSkuVersion;
      newEntry.productKey = item.productKey;
      newEntry.productVersion = item.productVersion;
      newEntry.documentUrl = item.documentUrl;
      newEntry.livePreviewUrl = item.livePreviewUrl;
      newEntry.promisedArrivalDate = item.promisedArrivalDate;
      newEntry.variableAttributes = transformAttributesListToObj(item.variableAttributes);
      // Only orders with items in these states can be resubmitted:
      if (apiItemState === "FailedItem" || apiItemState === "Rejected") {
        newEntry.canResubmit = true;
      } else {
        newEntry.canResubmit = false;
      }
      return newEntry;
    }
  }
  // Did not find Item ID!  Return row without extra info.
  console.log(
    `== WARNING: No matching item ${currentRow.itemId} in OMS order ${omsOrder.orderNumber}!`
  );
  return newEntry;
};

/**
 * Merge fields set in the BORED UI into this new row entry.
 * @param {*} currentRow Item from OM.
 * @param {*} newEntry Item being created.
 */
const mergeRowFields = (currentRow, newEntry) => {
  newEntry.isSelected = currentRow.isSelected;
  // Optional fields that may have been updated from the input file:
  newEntry.newDocSrcUrl = currentRow.newDocSrcUrl;
  newEntry.newSkuVersion = currentRow.newSkuVersion;
  newEntry.newSku = currentRow.newSku;
  newEntry.newProductKey = currentRow.newProductKey;
  newEntry.newProductVersion = currentRow.newProductVersion;
  newEntry.newVariableAttributes = currentRow.newVariableAttributes;
  newEntry.deletedVariableAttributes = currentRow.deletedVariableAttributes;
  // Preserve this user selection when refreshing
  newEntry.changedAttributes = currentRow.changedAttributes;
}

/**
 * Given an OMS/DALI order, create rows for ALL items in the order.
 * This supports isOrderOnly mode where NO itemId is provided in the data file.
 * @param {*} order OMS order.
 * @param {*} isRefresh 
 * @param {*} doMerge 
 * @returns Array of new rows to add.
 */
export const mergeAllOrderItems = (order, isRefresh, doMerge) => {
  const items = order.lineItems;
  if (!items || items.length === 0) {
    return [];
  }
  const rowArr = [];
  for (var i = 0; i < items.length; i++) {
    const item = items[i];
    const currentRow = {};
    currentRow.itemId = item.id;
    const newEntry = mergeOrderData(order, currentRow, isRefresh, doMerge);
    rowArr.push(newEntry);
  }
  return rowArr;
}

/**
 * Create a semi-unique filename for downloading data.
 * @returns 
 */
export const createDownloadFileName = () => {
  const now = new Date();
  const y = now.getFullYear();
  const m = now.getMonth();
  const d = now.getDay();
  const h = now.getHours();
  const min = now.getMinutes();
  return `bored-result-${m}-${d}-${y}-${h}${min}`;
}

/**
 * Convert file data model to CSV-exportable array form (with headers in 1st row).
 * @param {*} fileData 
 * @returns Array of data suitable to be exported as CSV.
 */
export const convertFileDataToArray = (fileData) => {
  const fileDataArray = [];
  const headers = getFileDataHeaders(fileData);

  fileData.forEach((fromRow) => {
    const toRow = [];
    headers.forEach((fieldName) => {
      if (fieldName === PROP_VARIABLE_ATTRS) {
        //Stringify and update the string to include two double quotes for one.
        toRow.push(JSON.stringify(fromRow[fieldName]).replace(/"/g, '""'));
      } else {
        toRow.push(fromRow[fieldName]);
      }
    });
    fileDataArray.push(toRow);
  });

  return fileDataArray;
}; 

/**
 * Extract an array of header names suitable for CSV export from a fileData model.
 * @param {*} fileData 
 * @returns Array of data header names.
 */
export const getFileDataHeaders = (fileData) => {
  const headers = [];

  if (fileData.length > 0) {
    const fieldNames = Object.getOwnPropertyNames(fileData[0]);
    fieldNames.forEach((fieldName) => {
      if (exportHeaderList.includes(fieldName)) {
        headers.push(fieldName);
      }
    });
  }

  return headers;
};
