import React from 'react';
import CSVReader from 'react-csv-reader';
import {
  Column,
  Divider,
  Row,
  H4,
  IconPolish,
} from '@vp/swan';
import PropTypes from 'prop-types';
import SingleOrderInput from './SingleOrderInput';
import {
  SECTION_HDR_COLOR,
  SECTION_HDR_PAD,
  SECTION_HDR_MARGIN,
  SECTION_HDR_BORDER
} from '../components/shared/AppStyle';

const NUDGE_RIGHT = 1;

const CSVUploader = ({
  title,
  onFileUpload,
  bottomMargin,
}) => {
  const csvReaderOptions = {
    header: false,  // We need to read the header names.
    skipEmptyLines: true,
  }
  return (
    <>
      <Row
        margin={SECTION_HDR_MARGIN}
        backgroundColor={SECTION_HDR_COLOR}
        padding={SECTION_HDR_PAD}
        style={SECTION_HDR_BORDER}
      >
        <Column>
          <H4><IconPolish />{" "}{title}</H4>
        </Column>
      </Row>
      <Row mb={5} ml={NUDGE_RIGHT}>
        <Column mr={3}>
          <CSVReader
            cssClass='swan-button'
            parserOptions={csvReaderOptions}
            onFileLoaded={(data, fileInfo) => {
              onFileUpload(data, fileInfo);
            }}
          />
        </Column>
        <Column>
          <SingleOrderInput onDataUploadFn={onFileUpload} />
        </Column>
      </Row>
      <Row>
        <Divider mb={bottomMargin} />
      </Row>
    </>
  )
};

CSVUploader.propTypes = {
  title: PropTypes.string,
  csvReaderOptions: PropTypes.object,
  onFileUpload: PropTypes.func,
  bottomMargin: PropTypes.number,
};

export default CSVUploader;
