import React from 'react';

import {
  TextInputWithFloatingLabel,
  TextInput,
  TextInputFloatingLabel,
  TabContent,
} from '@vp/swan';

/**
 * Tabs for getting inputs to set fields to update.
 */
const InputTabTextField = ({ label, setValueFn, inputIsDoneFn, value, tabId, type }) => (
  <TabContent tabId={tabId}>
    <TextInputWithFloatingLabel>
      <TextInput
        type={type}
        value={value}
        size="mini"
        onChange={setValueFn}
        onBlur={() => {
          inputIsDoneFn();
        }}
        />
      <TextInputFloatingLabel>{label}</TextInputFloatingLabel>
    </TextInputWithFloatingLabel>
  </TabContent>
);

export default InputTabTextField;
