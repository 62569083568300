import React, { createContext, useContext, useReducer } from 'react';
import fileDataReducer from './fileDataReducer.js';
import { PRODUCTION_ENV } from '../util/orderUtils.js';

/**
 * Contexts for order/item data, and for dispatching events to the reducer.
 * @see https://react.dev/learn/scaling-up-with-reducer-and-context
 */

const FileDataContext = createContext([]);
const FileDataDispatchContext = createContext(null);

/** Initial state of file data. */
export const EMPTY_DATA = [
  {
    orderNumber: 'No data',
    itemId: undefined,
    fulfillmentSku: '',
    fulfillmentSkuVersion: undefined,
    productKey: '',
    productVersion: '',
    orderState: '',
    itemState: '',
    isSelected: false,
  },
];

/**
 * Provider for the order/item data based on the input file.  MUST be placed as a parent of App.
 * @param {*} param0 
 * @returns 
 */
export function FileDataProvider({ children }) {
  const initialData = [];
  initialData.meta = {};
  initialData.meta.environment = PRODUCTION_ENV;

  const [fileData, dispatch] = useReducer(
    fileDataReducer,
    initialData,
  );

  return (
    <FileDataContext.Provider value={fileData}>
      <FileDataDispatchContext.Provider value={dispatch}>
        {children}
      </FileDataDispatchContext.Provider>
    </FileDataContext.Provider>
  );
}

/**
 * Convenience function for component that needs access to the file data.
 * @returns Current state of file data array.
 */
export function useFileData() {
  const ctx = useContext(FileDataContext);
  return ctx;
}

/**
 * Convenience function for components that need to dispatch an action indicating
 * a change by the user.
 * @returns Dispatch function 
 */
export function useFileDataDispatch() {
  const ctx = useContext(FileDataDispatchContext);
  return ctx;
}