import React, { useState, useEffect, useReducer } from 'react';
import {
  GridContainer,
  responsive,
  ScreenClassProvider,
} from '@vp/swan';
import { ErrorNotifications } from './errors/ErrorNotifications';
import CSVUploader from '../util/CSVUploader';
import FileDataTable from './FileDataTable';
import EnvironmentChooser from './EnvironmentChooser';
import RemediationSection from './RemediationSection';
import InputTabs from './InputTabs';
import { getOrders } from '../apis/omsApi.js';
import Validator from '../util/Validator';
import actionTypes from '../state/actionTypes';
import { useFileDataDispatch, useFileData } from '../state/FileDataContexts';
import { useErrorNotifierDispatch } from './errors/ErrorNotifier';
import { BAD_FILE_ERROR } from './errors/errorNotifierDispatchActions';
import { createApiError } from '../apis/apiUtils';
import { ActivityLogs } from './activityLogs/ActivityLogs';
import DownloadResults from './DownloadResults';
import { useActivityLoggerDispatch } from './activityLogs/ActivityLogger';
import {
  LOAD_FILE,
  LOAD_DATA_TABLE_START,
  LOAD_DATA_TABLE_END,
  LOAD_DATA_TABLE_ERROR,
} from "./activityLogs/activityLoggerDispatchActions";

const ResponsiveGridContainer = responsive(GridContainer);

const SECTION_MARGIN = 7;

/**
 * Main App
 */
const App = () => {
  const [gotOmsData, setGotOmsData] = useState(null);
  const dispatch = useFileDataDispatch();
  const fileData = useFileData();
  const { errorNotifierDispatch } = useErrorNotifierDispatch();
  const activityLoggerDispatch = useActivityLoggerDispatch();
  const env = fileData.meta.environment;

  /**
   * Read the selected file.
   * @param {*} data 
   * @param {*} fileInfo 
   */
  const doReadFile = (data, fileInfo) => {

    dispatch({
      type: actionTypes.DATA_CLEAR,
      environment: env
    });
    dispatch({
      type: actionTypes.ORDER_LOAD_START,
      source: `File:  ${fileInfo.name}`,
    })
    setGotOmsData(false);
    const parsedData = Validator.validate(env, data);
    if (!parsedData || parsedData.length === 0) {
      errorNotifierDispatch(BAD_FILE_ERROR(fileInfo.name));
      // fall into...
    }
    else {
      activityLoggerDispatch(LOAD_FILE(fileInfo.name));
    }
    dispatch({
      type: actionTypes.ORDER_LOAD_DONE,
      newData: parsedData
    });
  };

  /**
   * Fetch the orders listed in 'fileData' from OMS.
   */
  const fetchOrdersAsync = async (isRefresh) => {
    dispatch({
      type: actionTypes.ORDER_LOAD_START,
      source: "OMS API",
    });
    activityLoggerDispatch(LOAD_DATA_TABLE_START());
    try {
      const omsEntries = await getOrders(env, fileData, isRefresh);
      if (omsEntries && omsEntries.length > 0) {
        setGotOmsData(true);
        dispatch({
          type: actionTypes.ORDER_LOAD_DONE,
          newData: omsEntries
        });
      }
      activityLoggerDispatch(LOAD_DATA_TABLE_END());
    } catch (err) {
      activityLoggerDispatch(LOAD_DATA_TABLE_ERROR());
      errorNotifierDispatch(createApiError("GetOrder", `Request failed with status ${err.errorStatusCode}`));
    }
  };

  const fetchOrdersRefreshAsync = async () => {
    fetchOrdersAsync(true);
  }
  
  /** Call OMS when file data has changed. */
  useEffect(() => {
    if (gotOmsData === false) {
      fetchOrdersAsync(false);
    }
  }, [fileData, gotOmsData]);

  const fileHasAnyChangedData = fileData.length > 0 && fileData?.meta?.anyFileChanges;

  return (
    <ScreenClassProvider>
      <ResponsiveGridContainer >
        <EnvironmentChooser />
        <ErrorNotifications />
        <CSVUploader
          title="Upload or input your CSV data"
          onFileUpload={doReadFile}
          bottomMargin={SECTION_MARGIN}
        />
        <FileDataTable
          title="View loaded order data"
          bottomMargin={SECTION_MARGIN}
          triggerRefreshFn={fetchOrdersRefreshAsync}
        />
        {!fileHasAnyChangedData && (
          <InputTabs
            title="Set Fields to Update all or selected orders"
            bottomMargin={SECTION_MARGIN}
          />
        )}
        <RemediationSection
          title="Perform Remediation"
          bottomMargin={SECTION_MARGIN}
        />
        <DownloadResults
          title="Post-Remediation Results"
        />
        <ActivityLogs />
      </ResponsiveGridContainer>
    </ScreenClassProvider>
  );
};
export default App;
