import activityTypes from './activityTypes';

export const LOAD_FILE = (fileName) => {
  return {
    type: activityTypes.LOAD_FILE,
    message: `"${fileName}" file loaded.`
  };
}
export const CLEAR_LOGS = () => {
  return {
    type: activityTypes.CLEAR_LOGS,
    message: `Activity Logs cleared.`
  };
}
export const LOAD_DATA_TABLE_START = () => {
  return {
    type: activityTypes.LOAD_DATA_TABLE,
    message: `Start loading data into table.`,
  };
};
export const LOAD_DATA_TABLE_END = () => {
  return {
    type: activityTypes.LOAD_DATA_TABLE,
    message: `End loading data into table.`,
  };
};
export const LOAD_DATA_TABLE_ERROR = () => {
  return {
    type: activityTypes.LOAD_DATA_TABLE,
    message: `Loading data into table FAILED.`,
  };
};
export const RESUBMIT_ORDERS = (orderNumbers) => {
  return {
    type: activityTypes.RESUBMIT_ORDER,
    message: `Resubmitting ${orderNumbers.length} orders.`,
  };
};
export const RESUBMIT_ORDER_SUCCESS = (orderNumber) => {
  return {
    type: activityTypes.RESUBMIT_ORDER,
    message: `Order ${orderNumber} resubmitted successfully.`,
  };
};
export const RESUBMIT_ORDER_FAILURE = (orderNumber) => {
  return {
    type: activityTypes.RESUBMIT_ORDER,
    message: `Order ${orderNumber} resubmission FAILED.`,
  };
};
export const REPLACE_ITEM_SUCCESS = (orderNumber, itemId) => {
  return {
    type: activityTypes.REPLACE_ITEM,
    message: `Order ${orderNumber}, item ${itemId} replaced successfully.`,
  };
};
export const REPLACE_ITEM_FAILURE = (orderNumber, itemId) => {
  return {
    type: activityTypes.REPLACE_ITEM,
    message: `Replacement of order ${orderNumber}, item ${itemId} FAILED.`,
  };
};
export const UPDATE_ITEM_SUCCESS = (orderNumber, itemId) => {
  return {
    type: activityTypes.UPDATE_ITEM,
    message: `Order ${orderNumber}, item ${itemId} was updated successfully.`,
  };
};
export const UPDATE_ITEM_FAILURE = (orderNumber, itemId) => {
  return {
    type: activityTypes.UPDATE_ITEM,
    message: `Update to ${orderNumber}, item ${itemId} FAILED.`,
  };
};
export const UPDATE_ITEM_DOCURL_SUCCESS = (orderNumber, itemId) => {
  return {
    type: activityTypes.UPDATE_ITEM,
    message: `Order ${orderNumber}, item ${itemId} document source URL was updated successfully.`,
  };
};
export const UPDATE_ITEM_DOCURL_FAILURE = (orderNumber, itemId) => {
  return {
    type: activityTypes.UPDATE_ITEM,
    message: `Update to ${orderNumber}, item ${itemId} document source URL FAILED.`,
  };
};
export const REFRESH_ORDER_DATA_SUCCESS = () => {
  return {
    type: activityTypes.REFRESH_ORDER_DATA,
    message: `Orders were re-fetched successfully.`,
  };
};
export const REFRESH_ORDER_DATA_FAILURE = () => {
  return {
    type: activityTypes.REFRESH_ORDER_DATA,
    message: `Refetch of orders FAILED.`,
  };
};