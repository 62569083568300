import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  H4,
  IconChevronRight,
  IconPolish,
  Row,
  Tabs,
  TabsHeaders,
  TabHeader,
  TabsContents,
  TabContent,
  Typography,
  Column,
} from "@vp/swan";

import InputTabTextField from './InputTabTextField.js';
import VariableAttributes from './VariableAttributes.js';
import { useFileDataDispatch } from '../state/FileDataContexts.js';
import ChangedValue from './ChangedValue.js';
import actionTypes from '../state/actionTypes.js';
import { SECTION_HDR_COLOR, SECTION_HDR_PAD, SECTION_HDR_MARGIN, SECTION_HDR_BORDER } from '../components/shared/AppStyle';

const NO_WRAP_STYLE = { 'white-space': 'nowrap' };

/** Format an attribute suitable to be displayed. */
const formatAttrDisplay = (attrObj) => {
  let strAttrDisplay = "";
  if (!attrObj) {
    return strAttrDisplay;
  }
  if (Array.isArray(attrObj)) {
    for (var i = 0; i < attrObj.length; i++) {
      const a = attrObj[i];
      strAttrDisplay = `${strAttrDisplay} '${a}',`;
    }
  } else {
    const keys = Object.getOwnPropertyNames(attrObj);
    for (var k = 0; k < keys.length; k++) {
      const key = keys[k];
      strAttrDisplay = ` ${strAttrDisplay} '${key}' => '${attrObj[key]}',`;
    }
  }
  strAttrDisplay = strAttrDisplay.slice(0, -1);
  return strAttrDisplay;
}

/**
 * Tabs for getting inputs to set fields to update.
 */
const InputTabs = ({ title, bottomMargin }) => {
  const [newSkuVersion, setNewSkuVersion] = useState('');
  const [newSku, setNewSku] = useState('');
  const [newProductVersion, setNewProductVersion] = useState('');
  const [newProductKey, setNewProductKey] = useState('');
  const [changedVarAttr, setChangedVarAttr] = useState('');
  const [deletedVarAttrs, setDeletedVarAttrs] = useState([]);

  const dispatch = useFileDataDispatch();

  /**
   * Upon field input, construct an object with any changed fields.
   * Dispatch changes to reducer.  Note that these changes do NOT include any variable attributes.
   */
  const updateData = () => {
    const rowUpdate = {};

    if (newSku) {
      rowUpdate.newSku = newSku;
    }
    if (newSkuVersion) {
      rowUpdate.newSkuVersion = newSkuVersion;
    }
    if (newProductKey) {
      rowUpdate.newProductKey = newProductKey;
    }
    if (newProductVersion) {
      rowUpdate.newProductVersion = newProductVersion;
    }
   
    dispatch({
      type: actionTypes.UI_DATA_CHANGED,
      changedData: rowUpdate,
    });
  };

  /**
   * Ensure input is numeric.
   * @param {*} value Value to be validated.
   * @param {*} setFn Function to call if value is valid.
   */
  const validateAndSetIntegerInput = (value, setFn) => {
    if (!isNaN(value)) {
      setFn(parseInt(value));
    };
  };

  return (
    <>
      <Row
        margin={SECTION_HDR_MARGIN}
        backgroundColor={SECTION_HDR_COLOR}
        padding={SECTION_HDR_PAD}
        style={SECTION_HDR_BORDER}
      >
        <H4><IconPolish />{" "}{title}</H4>
      </Row>
      <Row ml={1}>
        <Column span={8}>
          <Tabs defaultSelectedTabId="skuVersion">
            <TabsHeaders>
              <TabHeader tabId="skuVersion">SKU Version</TabHeader>
              <TabHeader tabId="sku">SKU</TabHeader>
              <TabHeader tabId="productVersion">Product Version</TabHeader>
              <TabHeader tabId="productKey">Product Key</TabHeader>
              <TabHeader tabId="varAttrs">VarAttrs</TabHeader>
            </TabsHeaders>
            <TabsContents>
              <InputTabTextField
                type="number"
                value={newSkuVersion}
                label="Enter integer value for new SKU version"
                setValueFn={(e) =>
                  validateAndSetIntegerInput(e.target.value, setNewSkuVersion)
                }
                tabId="skuVersion"
                inputIsDoneFn={updateData}
              />
              <InputTabTextField
                type="text"
                value={newSku}
                label="Enter value for new SKU"
                setValueFn={(e) => setNewSku(e.target.value)}
                tabId="sku"
                inputIsDoneFn={updateData}
              />
              <InputTabTextField
                type="number"
                value={newProductVersion}
                label="Enter integer value for new product version"
                setValueFn={(e) =>
                  validateAndSetIntegerInput(
                    e.target.value,
                    setNewProductVersion
                  )
                }
                tabId="productVersion"
                inputIsDoneFn={updateData}
              />
              <InputTabTextField
                type="text"
                value={newProductKey}
                label="Enter value for new Product Key"
                setValueFn={(e) => setNewProductKey(e.target.value)}
                tabId="productKey"
                inputIsDoneFn={updateData}
              />
              <TabContent tabId="varAttrs">
                <VariableAttributes
                  setChangedValueFn={setChangedVarAttr}
                  setDeletedFn={setDeletedVarAttrs}
                />
              </TabContent>
            </TabsContents>
          </Tabs>
        </Column>
        <Column padding={2} margin={2}  style={{ borderColor: "grey", borderWidth: "2px", "border-style": "solid", "border-radius": "5px" }} span={6} offset={1}>
          <Row>
            <Typography fontWeight="bold" paddingLeft={3} >Changed Fields:</Typography>
            <ChangedValue value={newSkuVersion} label="New SKU Version" />
          </Row>
          <Row>
            <ChangedValue value={newSku} label="New SKU" />
          </Row>
          <Row>
            <ChangedValue
              value={newProductVersion}
              label="New Product Version"
            />
          </Row>
          <Row>
            <ChangedValue
              value={newProductKey}
              label="New Product Key" />
          </Row>
          <Row>
            <ChangedValue
              value={changedVarAttr && formatAttrDisplay(changedVarAttr)}
              label="Changed attributes" />
          </Row>
          <Row style={NO_WRAP_STYLE}>
            <ChangedValue
              value={deletedVarAttrs.length > 0 && formatAttrDisplay(deletedVarAttrs)}
              label="Deleted attributes" />
          </Row>
        </Column>
      </Row>
      <Row>
        <Divider mb={bottomMargin} />
      </Row>
    </>
  );
};

InputTabs.propTypes = {
  title: PropTypes.string,
  bottomMargin: PropTypes.number,
};

export default InputTabs;
