import React from 'react';
import {
  Column,
  Link,
  Row,
  Dropdown,
  DropdownOption,
  PaginationLabel,
  PaginationButton,
  StepIndicator,
  StepIndicatorStep,
  StepIndicatorStepList,
} from '@vp/swan';
import PropTypes from "prop-types";

const PAGE_SIZES = [ 5, 10, 20, 100];

export const PaginationController = ({
  curPage,
  lastPage,
  prevPageFn,
  nextPageFn,
  changePageSizeFn,
  toPageFn,
}) => {
  const steps = [];
  for (var i = 0; i <= lastPage; i++) {
    steps.push(i);
  }
  return (
    <Row mb={3}>
      <Column span={1} offset={3}>
        <Dropdown
          size="mini"
          onChange={event => changePageSizeFn(event.target.value)}
        >
          {PAGE_SIZES.map((s) => {
            return (
              <DropdownOption value={s}>{s}</DropdownOption>
            )
          })}
        </Dropdown>
      </Column>
      <Column span={1}>
        <PaginationLabel>
          Page {curPage + 1} of {lastPage + 1}
        </PaginationLabel>
      </Column>
      <Column span={0}>
      <PaginationButton
        variant="previous"
        href="#"
        accessibleText="Previous Page"
        disabled
        onClick={() => prevPageFn()}
        />
      </Column>
      <Column span={2}>
        <StepIndicator currentStepNumber={curPage}>
          <StepIndicatorStepList>
            {steps.map((s) => {
              return (
                <StepIndicatorStep stepNumber={s} onClick={() =>toPageFn(s)}>
                <Link>{s + 1}</Link>
                </StepIndicatorStep>
              );
            })}
          </StepIndicatorStepList>
        </StepIndicator>
      </Column>
      <Column span={0}>
        <PaginationButton
          variant="next"
          href="#"
          accessibleText="Next Page"
          disabled
          onClick={() => nextPageFn()}
          />
      </Column>
    </Row>
  );
}

PaginationController.propTypes = {
  curPage: PropTypes.number,
  lastPage: PropTypes.number,
  nextPageFn: PropTypes.func,
  prevPageFn: PropTypes.func,
  changePageSizeFn: PropTypes.func,
  toPageFn: PropTypes.func,
};


