import React, { createContext, useContext, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';
import errorTypes from './errorTypes';

const NotificationDispatchContext = createContext(); // conxtext for the write-only dispatch
const NotificationDataContext = createContext(); // context for the read-only error state

/**
 * Error message context provider that displays certain error messages on top of the page.
 * @param {*} initialStateOverride (Optional) Test only, set up initial state with given object, for testing only
 */
export const ErrorNotifierProvider = ({ children, initialStateOverride }) => {
  const defaultInitialState = {
    errors: [],
  };

  const reducer = (state, action) => {

    switch (action.type) {
      case errorTypes.CLEAR_ERRORS: {
        return defaultInitialState;
      }
      default: {
        // If the error exists, increment the failure count by 1. If not, start from 0.
        const oldFailureCount =
          state.errors.find(error => error.type === action.type)?.failureCount || 0;
        const newFailureCount = oldFailureCount + 1;
        const message = action.message;

        const newState = {
          ...state,
          errors: [
            // Remove the existing error (if needed) and add a new error object to the array.
            ...state.errors.filter(error => error.type !== action.type),
            {
              type: action.type,
              severity: action.severity,
              failureCount: newFailureCount,
              message,
            },
          ],
        };
        return newState;
      }
    }
  };

  const initialState = initialStateOverride || defaultInitialState;

  const [errorNotifierState, errorNotifierDispatch] = useReducer(
    reducer,
    initialState
  );

  const notificationDataContextValues = useMemo(
    () => ({ errorNotifierState }),
    [errorNotifierState]
  );

  const notificationDispatchContextValues = useMemo(
    () => ({ errorNotifierDispatch }),
    [errorNotifierDispatch]
  );

  return (
    <NotificationDataContext.Provider value={notificationDataContextValues}>
      <NotificationDispatchContext.Provider
        value={notificationDispatchContextValues}
      >
        {children}
      </NotificationDispatchContext.Provider>
    </NotificationDataContext.Provider>
  );
};

/**
 * Custom hook exporting error notification state
 * @example
 * const { errorNotifierState } = useErrorNotifierState();
 * @example
 * errorNotifierState: {
 *  errors: [
 *    type: String,
 *    message: String,
 *    failureCount: Number,
 * ]
 * }
 */
export const useErrorNotifierState = () => {
  const { errorNotifierState } = useContext(NotificationDataContext);
  if (errorNotifierState === undefined) {
    throw new Error(
      'useErrorNotifierState must be used within ErrorNotifierProvider!'
    );
  }
  return { errorNotifierState };
};

/**
 * Custom hook exporting error notification dispatch.
 * @example
 * const { errorNotifierDispatch } = useErrorNotifierDispatch();
 * @param {*} action Dispatch action. See example below
 * @example
 * {
 *  type(required): 'FILE'
 * }
 */
export const useErrorNotifierDispatch = () => {
  const { errorNotifierDispatch } = useContext(NotificationDispatchContext);
  if (errorNotifierDispatch === undefined) {
    throw new Error(
      'useErrorNotifierDispatch must be used within ErrorNotifierProvider!'
    );
  }
  return { errorNotifierDispatch };
};

ErrorNotifierProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  initialStateOverride: PropTypes.object,
};
