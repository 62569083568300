import React from 'react';
import {
  Typography
} from '@vp/swan';

const STYLE_COLOR_RED = { color: "red" };

/**
 * Component to display a changed value in special color.
 * @param {*} param0 
 * @returns 
 */
const ChangedValue = ({ value, label }) => {
  if (value) {
    return (
      <>
        <Typography paddingRight={2} paddingLeft={3}>{label}: </Typography>
        <Typography style={STYLE_COLOR_RED}> {value}</Typography>
        <br />
      </>
    );
  }
  return (<></>);
}

export default ChangedValue;