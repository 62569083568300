import React from 'react';
import PropTypes from "prop-types";
import {
  Button,
  H4,
  ModalDialog,
  ModalDialogBody,
  ModalDialogContent,
  ModalDialogFooter,
  ModalDialogButtons,
} from "@vp/swan";

/**
 * Modal Window for viewing full URLs.  Two URLs are supported.
 * @param {*} param0 
 * @returns 
 */
const UrlDisplayModal = ({ url1, url2, isOpen, setIsOpenFn }) => {
  return (
    <ModalDialog isOpen={isOpen} onRequestDismiss={() => setIsOpenFn(false)}>
      <ModalDialogContent aria-labelledby="modal dialog general">
        <ModalDialogBody>
          <H4>Current Doc URL: </H4>{url1} <br />
          {url2 ? (<H4>New URL:</H4>) : ''} {url2}
        </ModalDialogBody>
        <ModalDialogFooter>
          <ModalDialogButtons>
            <Button onClick={() => setIsOpenFn(false)}>Close</Button>
          </ModalDialogButtons>
      </ModalDialogFooter>
      </ModalDialogContent>
    </ModalDialog>
  )
}

UrlDisplayModal.propTypes = {
  url1: PropTypes.string,
  url2: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpenFn: PropTypes.func,
};

export default UrlDisplayModal;