import { useContext } from 'react';
import { IdentityContext } from '../components/contexts/IdentityContext';

// https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/171245584/Custom+JWT+Claims
const customerClaim = 'https://claims.cimpress.io/is_customer';
const canonicalIDClaim = 'https://claims.cimpress.io/canonical_id';
const anonymousClaim = 'https://claims.cimpress.io/is_anonymous';
const cimpressInternalClaim = 'https://claims.cimpress.io/cimpress_internal';

const useIdentityContext = () => {
  const { isIdentityInitialized, identity, auth } = useContext(IdentityContext);

  // Build an identity container including only the information needed within the application.
  // The container either represents a valid logged in shopper, a valid logged in internal user, or an anonymous user.
  const identityContainer = {};
  if (isIdentityInitialized && identity && identity.isSignedIn) {
    identityContainer.isSignedIn = true;

    // shopper
    if (identity.profile[customerClaim]) {
      identityContainer.shopperId = identity.profile[canonicalIDClaim];
      identityContainer.isShopper = true;
    }

    // internal user
    if (identity.profile[cimpressInternalClaim]) {
      identityContainer.cimpressADFSUserId = identity.profile[canonicalIDClaim];
      identityContainer.isInternalUser = true;
    }
  }

  // anonymous
  if (isIdentityInitialized && identity && !!identity.profile[anonymousClaim]) {
    identityContainer.isSignedIn = identity.isSignedIn;
    identityContainer.isAnonymousUser = true;
    identityContainer.anonymousUserId = identity.profile[canonicalIDClaim];
  }

  return {
    isIdentityInitialized,
    identity: identityContainer,
    auth,
  };
};

export default useIdentityContext;
