import VistaprintAuth from '@vp/auth';

export default function getIdTokenAndShopperId() {
  const auth = new VistaprintAuth.WebAuth();

  return {
    idToken: auth.getToken(),
    shopperId:
      auth.isSignedIn() &&
      auth.getProfile()['https://claims.cimpress.io/canonical_id'],
  };
}
