import React, { useState } from 'react';

import {
  Button,
  Column,
  Divider,
  H4,
  IconPolish,
  Row,
  TextInputWithFloatingLabel,
  TextInput,
  TextInputFloatingLabel,
} from '@vp/swan';
import PropTypes from 'prop-types';
import AllOrSelectedTabs from './AllOrSelectedTabs';
import PreviewTable from './PreviewTable';
import DownloadResults from './DownloadResults';
import { useFileData } from '../state/FileDataContexts';
import {
  SECTION_HDR_COLOR,
  SECTION_HDR_PAD,
  SECTION_HDR_MARGIN,
  SECTION_HDR_BORDER
} from '../components/shared/AppStyle';

const RemediationSection = ({
  title,
  bottomMargin,
}) => {
  const [remediationReason, setRemediationReason] = useState('');
  const [isPreviewShown, setPreviewShown] = useState(false);
  const fileData = useFileData();

  const setErrorFn = () => {
    console.log('error!');
  };
  const hasData = fileData && fileData.length > 0 && fileData[0].itemId;
  const showPreview = (isShown) => {
    setPreviewShown(isShown);
  }

  return (
    <>
      <Row
        margin={SECTION_HDR_MARGIN}
        backgroundColor={SECTION_HDR_COLOR}
        padding={SECTION_HDR_PAD}
        style={SECTION_HDR_BORDER}
      >
        <H4><IconPolish />{" "}{title}</H4>
      </Row>
      <Row mb={5} ml={1}>
        <Column span={6}>
        <TextInputWithFloatingLabel>
          <TextInput
            size="mini"
            placeholder="Reason for remediation"
            onChange={event => {
              if (event.target.value !== '') {
                setErrorFn(false);
              }
              setRemediationReason(event.target.value);
            }}
          />
          <TextInputFloatingLabel>
            Enter reason for remediation
          </TextInputFloatingLabel>
          </TextInputWithFloatingLabel>
          </Column>
      </Row>
      {hasData && (
        <>
          <Row>
            <Column span={12}>
              <PreviewTable
                title="Remediation Preview"
                isVisible={isPreviewShown}
                setVisible={showPreview}
                bottomMargin={5}
              />
            </Column>
          </Row>
          {!isPreviewShown && (
            <Row mb={3}>
              <Button
                size="mini"
                onClick={() => showPreview(true)}
                disabled={false}
              >
              Preview Remediated Orders
              </Button>
            </Row>
          )}
        </>
       )}
      <Row ml={1}>
        <Column span={12}>
          <AllOrSelectedTabs
            remediationReason={remediationReason}
          />
        </Column>
      </Row>
      <Row>
        <Divider mb={bottomMargin} />
      </Row>
    </>
  );
};

RemediationSection.propTypes = {
  title: PropTypes.string,
  bottomMargin: PropTypes.number,
};

export default RemediationSection;
