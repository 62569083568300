import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, AlertBox, AlertBoxDismissButton } from '@vp/swan';
import ErrorSeverity from './ErrorSeverity';

/**
 * TODO: Shared notification banner for address/vat CRUD operation failures
 * could be placed on top of AddressUI, or somewhere in a modal
 * @param {*} error the error object with message to be displayed
 */
export const ErrorNotification = ({ error }) => {

  const [dismissed, setDismissed] = useState(false);
  const skin = error.severity === ErrorSeverity.WARNING ? "warning" : "error";

  useEffect(() => {
    setDismissed(false);
  }, [error.failureCount]);

  return (
    <AlertBox
      mt={2}
      ml={3}
      skin={skin}
      dismissed={dismissed}
      onRequestDismiss={() => {
        setDismissed(true);
      }}
    >
      <Typography>{error.message}</Typography>
      <AlertBoxDismissButton visuallyHiddenLabel="Dismiss alert" />
    </AlertBox>
  );
};

ErrorNotification.propTypes = {
  error: PropTypes.any.isRequired,
};
