import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Column,
  Divider,
  FlexBox,
  H4,
  IconPolish,
  Pagination,
  Row,
  SelectionSet,
  SelectionSetInput,
  SelectionSetLabel,
  SelectionSetTileContents,
  SelectionSetTileName,
  SelectionSetTilePrice,
  Table,
  TableScrollContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TableTextNewLine,
  TableCaption,
  Typography,
} from "@vp/swan";
import { useFileDataDispatch, useFileData } from "../state/FileDataContexts";
import actionTypes from "../state/actionTypes";
import AttributesDisplay from "./AttributesDisplay.js";
import { CSVExporter } from "../util/CSVExporter";
import {
  getFileDataHeaders,
  convertFileDataToArray
} from "../util/orderUtils";
import { PROP_VARIABLE_ATTRS } from "../util/orderUtils.js";
import { PaginationController } from './PaginationController';
import UrlDisplayModal from './UrlDisplayModal';
import { toInteger } from 'lodash';

const ROW_BORDER_STYLE = { border: "1px solid gray" };
const SELECTION_LABEL_STYLE = { width: "100%", padding: "5px" };
const NO_WRAP_STYLE = { 'white-space': 'nowrap' };
const PAGE_SIZES = [5, 10, 100];

/**
 * Convert selection object to selection array.
 * @param {*} curSelectionsArr Array of booleans.
 * @param {*} newSelectionsObj Object where each index property is a boolean value.
 * @returns Array of boolean values.
 */
const getSelectedRows = (curSelectionsArr, newSelectionsObj) => {
  const rows = [];
  for (var n = 0; n < curSelectionsArr.length; n++) {
    rows[n] = newSelectionsObj[n];
  }
  return rows;
};

/**
 * Main Data Table display.
 */
const FileDataTable = ({ title, bottomMargin, triggerRefreshFn }) => {
  const [curPage, setCurPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [curPageSize, setCurPageSize] = useState(PAGE_SIZES[0]);
  const dispatch = useFileDataDispatch();
  const fileData = useFileData();
  const itemCount = fileData?.length || 0;
  const hasNewVariableAttrs = fileData.meta?.hasNewVariableAttributes ? true : false;
  const hasDeletedVariableAttrs = fileData.meta?.hasDeletedVariableAttributes ? true : false;

  /** When file data size goes to 0 on DATA_CLEAR, make sure curPage & curPageSize is reset. */
  useEffect(() => {
    if (itemCount === 0) {
      setCurPage(0);
      setCurPageSize(PAGE_SIZES[0]);
    }
  }, [fileData]);

  // Paging management.  Item and page values are 0-based, while display is 1-based.
  const firstRowShown = curPage * curPageSize;
  const lastRowShown = firstRowShown + curPageSize - 1;
  const lastPage = Math.ceil(itemCount / curPageSize) - 1;
  const fileDataPage = fileData ? fileData.slice(firstRowShown, lastRowShown + 1) : [];

  // Enforce not going beyond the last page.
  const toNextPage = () => {
    if (curPage < lastPage) {
      setCurPage(curPage + 1);
    }
  }
  // Enforce not going less than page 0.
  const toPrevPage = () => {
    if (curPage > 0) {
      setCurPage(curPage - 1);
    }
  }

  // Changing page size jumps us back to the top of the data.
  const changePageSize = (newSizeStr) => {
    const newSize = toInteger(newSizeStr);
    setCurPageSize(newSize);
    setCurPage(0);
  }

  // 
  const goToThisPage = (pageNo) => {
    setCurPage(pageNo);
  } 

  // Selection management
  const doSelectionChange = (newSelectedValues) => {
    const rows = getSelectedRows(selectionList, newSelectedValues);
    dispatch({
      type: actionTypes.SELECTION_CHANGED,
      selectedRows: rows,
    });
  };

  // Build selectionList for SelectionSet API.
  const selectionList = [];
  for (var i = 0; i < fileData.length; i++) {
    selectionList.push(fileData[i].isSelected);
  }

  const itemCountStr = itemCount > 0
    ? `(${itemCount} item${itemCount === 1 ? '' : 's'})`
    : '';
  
  const getChangedValueMarkup = (value) => {
    if (value) {
      return <span style={{ color: "red" }}> {" =>" + value}</span>;
    }
  };

  return (
    <>
      <Pagination>
        {fileData && fileData.length > 0 && (
          <PaginationController
            curPage={curPage}
            lastPage={lastPage}
            prevPageFn={toPrevPage}
            nextPageFn={toNextPage}
            changePageSizeFn={changePageSize}
            toPageFn={goToThisPage}
          />
        )}
        <Row>
          <Column span={10}>
            <TableScrollContainer>
              <SelectionSet
                variant="multi-select"
                skin="tiles-mini"
                selectedValues={selectionList}
                onSelectedValuesChange={(newSelValues) =>
                  doSelectionChange(newSelValues)
                }
              >
                <Table skin="simple">
                  <TableCaption>
                    <H4>
                      <IconPolish /> {title} {itemCountStr}
                    </H4>
                  </TableCaption>
                  <TableHead>
                    <TableRow style={ROW_BORDER_STYLE}>
                      <TableCell>#</TableCell>
                      <TableCell>
                        Order Number<TableTextNewLine>Item ID</TableTextNewLine>
                      </TableCell>
                      <TableCell>
                        Quantity
                        <TableTextNewLine>(Prom. Arr. Date)</TableTextNewLine>
                      </TableCell>
                      <TableCell>
                        SKU
                        <TableTextNewLine>SKU version</TableTextNewLine>
                      </TableCell>
                      <TableCell>
                        Product Key
                        <TableTextNewLine>Product version</TableTextNewLine>
                      </TableCell>
                      <TableCell>
                        Order State
                        <TableTextNewLine>Item State</TableTextNewLine>
                      </TableCell>
                      <TableCell>Doc URL</TableCell>
                      <TableCell>Current Variable Attributes</TableCell>
                      {(hasNewVariableAttrs || hasDeletedVariableAttrs) && (
                        <TableCell>New/Deleted Variable Attributes</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* No data display */}
                    {fileData.length === 0 && (
                      <TableRow style={ROW_BORDER_STYLE}>
                        <TableCell>No data</TableCell>
                      </TableRow>
                    )}
                    {/* Data Table display */}
                    {fileData &&
                      fileData.length > 0 &&
                      fileDataPage.map((o, i) => (
                        <TableRow style={ROW_BORDER_STYLE}>
                          <TableCell>{i + firstRowShown + 1}.</TableCell>
                          <TableCell style={NO_WRAP_STYLE}>
                            <SelectionSetInput value={i}>
                              <SelectionSetLabel style={SELECTION_LABEL_STYLE}>
                                <SelectionSetTileContents mb="0" mt="0">
                                  <SelectionSetTileName>
                                    {o.orderNumber}
                                  </SelectionSetTileName>
                                  <SelectionSetTilePrice>
                                    {o.itemId}
                                  </SelectionSetTilePrice>
                                </SelectionSetTileContents>
                              </SelectionSetLabel>
                            </SelectionSetInput>
                          </TableCell>
                          <TableCell>
                            {o.quantity}
                            <TableTextNewLine>({o.promisedArrivalDate || 'none'})</TableTextNewLine>
                          </TableCell>
                          <TableCell>
                            {o.fulfillmentSku}
                            <TableTextNewLine>
                              {o.skuVersion}{" "}
                              {getChangedValueMarkup(o.newSkuVersion)}
                            </TableTextNewLine>
                          </TableCell>
                          <TableCell>
                            {o.productKey}
                            <TableTextNewLine>
                              {o.productVersion}{" "}
                              {getChangedValueMarkup(o.newProductVersion)}
                            </TableTextNewLine>
                          </TableCell>
                          <TableCell>
                            {o.orderState}
                            <TableTextNewLine>
                              {o.itemState}{" "}
                              {getChangedValueMarkup(o.updatedItemState)}
                            </TableTextNewLine>
                          </TableCell>
                          <TableCell>
                            {o.documentUrl ? (
                              <Button
                                size="mini"
                                onClick={() => setIsModalOpen(true)}
                              >
                                URL
                                {o.newDocSrcUrl
                                  ? getChangedValueMarkup("URL")
                                  : ""}
                              </Button>
                            ) : (
                              ""
                            )}
                            <UrlDisplayModal
                              isOpen={isModalOpen}
                              setIsOpenFn={setIsModalOpen}
                              url1={o.documentUrl}
                              url2={o.newDocSrcUrl}
                            />
                          </TableCell>
                          <TableCell style={NO_WRAP_STYLE} textAlign="left">
                            <AttributesDisplay
                              attributes={o.variableAttributes}
                            />
                          </TableCell>
                          {(hasNewVariableAttrs || hasDeletedVariableAttrs) && (
                            <TableCell style={NO_WRAP_STYLE}>
                              <AttributesDisplay
                                attributes={o.newVariableAttributes}
                                areNewAttrs={true}
                                deletedAttributes={o.deletedVariableAttributes}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </SelectionSet>
            </TableScrollContainer>
            <Row mt={2}>
              {fileData && fileData.length > 0 && (
                <>
                  <Column span={5}>
                    <Button onClick={triggerRefreshFn} size="mini" mr={3}>
                      Refresh Data
                    </Button>
                    <CSVExporter
                      title="Download Table Data"
                      headers={getFileDataHeaders(fileData)}
                      csvData={convertFileDataToArray(fileData)}
                      filename={getFileName()}
                    />
                  </Column>
                  <Column span={4} offset={2}>
                    <FlexBox flexDirection={"column"}>
                      <Typography>
                        {selectionList?.length > 0 ? "Currently Selected:" : ""}
                      </Typography>
                      {selectionList?.length > 0 &&
                        selectionList.map((isSel, i) => (
                          <Row textColor="holiday">
                            {isSel && fileData[i].orderNumber}
                          </Row>
                        ))}
                    </FlexBox>
                  </Column>
                </>
              )}
            </Row>
          </Column>
        </Row>
      </Pagination>
      <Row>
        <Divider mb={bottomMargin} />
      </Row>
    </>
  );
};

const getFileName = () => {
  const prefix = "data";
  let date = new Date().toJSON().replace(/:/g, "").replace(/Z/g, ""); // 2022-06-17T11:06:50.369
  return prefix.concat(" - ", date, ".csv");
}

FileDataTable.propTypes = {
  title: PropTypes.string,
  bottomMargin: PropTypes.number,
  triggerRefreshFn: PropTypes.func,
};

export default FileDataTable;
