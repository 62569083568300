import React from 'react';
import { CSVLink } from 'react-csv';
import { Button } from '@vp/swan';
import PropTypes from 'prop-types';
/*
 * Display text button to export results from the FileDataTable in a CSV file
  */
export const CSVExporter = ({ title, headers, csvData, filename }) => {
  
  return (
    <>
      <Button size="mini">
        <CSVLink
          data={csvData}
          headers={headers}
          enclosingCharacter={`"`}
          filename={filename}
        >
          {title}
        </CSVLink>
      </Button>
    </>
  );
};

CSVExporter.propTypes = {
  title: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.object),
  exportFile: PropTypes.string,
};

export default CSVExporter;