import React, { useEffect } from 'react';
import { FlexBox, Spinner } from '@vp/swan';
import { initializeNewRelicTrackingContainer } from '@vp/om-newrelic-tracker';
import useIdentityContext from '../hooks/useIdentityContext';
import { FileDataProvider } from '../state/FileDataContexts';
import { ErrorNotifierProvider } from './errors/ErrorNotifier';
import { ActivityLoggerProvider } from './activityLogs/ActivityLogger';
import App from './App';

const AppSession = () => {
  const { isIdentityInitialized, identity, auth } = useIdentityContext();
  const { isSignedIn, isAnonymousUser, shopperId } = identity;

  const siteName = 'VP-US';
  const locale = 'en-us';

  if (isIdentityInitialized && isAnonymousUser) {
    auth.signIn();
  }

  useEffect(() => {
    initializeNewRelicTrackingContainer({
      actionRoot: 'om-addresses-ui',
      shopperId,
      locale,
      site: siteName,
      tenant: 'not-applicable',
    });
  }, [locale, shopperId, siteName]);

  return isSignedIn ? (
    <>
      <FileDataProvider>
        <ErrorNotifierProvider>
          <ActivityLoggerProvider>
            <App />
          </ActivityLoggerProvider>
        </ErrorNotifierProvider>
      </FileDataProvider>
    </>
  ) : (
    <FlexBox justifyContent="center">
      <Spinner
        size="super"
        accessibleText='Preloader'
        aria-busy="true"
        aria-live="polite"
        role="status"
        mt={6}
        mb={6}
      />
    </FlexBox>
  );
};

export default AppSession;
