import React, { useState } from 'react';
import {
  Column,
  Dropdown,
  DropdownOption,
  DropdownFloatingLabel,
  DropdownWithFloatingLabel,
  Row,
} from '@vp/swan';
import { useFileDataDispatch } from "../state/FileDataContexts";
import actionTypes from '../state/actionTypes.js';
import { PRODUCTION_ENV, STAGING_ENV } from '../util/orderUtils';

const EnvironmentChooser = () => {
  const [currentEnv, setCurrentEnv] = useState(PRODUCTION_ENV);
  const dispatch = useFileDataDispatch();

  const changeEnv = (val) => {
    setCurrentEnv(val);
    dispatch({
      newEnvironment: val,
      type: actionTypes.ENVIRONMENT_CHANGED,
    });
  };

  return (
    <Row>
      <Column offset={8} span={2}>
          <DropdownWithFloatingLabel>
            <DropdownFloatingLabel>Environment</DropdownFloatingLabel>
            <Dropdown
              onChange={event => changeEnv(event.target.value)}
            >
              <DropdownOption value={PRODUCTION_ENV}>{PRODUCTION_ENV}</DropdownOption>
              <DropdownOption value={STAGING_ENV}>{STAGING_ENV}</DropdownOption>
          </Dropdown>
          </DropdownWithFloatingLabel>
      </Column>
    </Row>
  )

};

export default EnvironmentChooser;